var checkLastMinuteBooking = function(atts){

  this.error = false;
  this.debug = {text: 'debugLastMinuteBooking', status: "no", error: false}

  this.check = function(){

    if (typeof atts.exclude === 'undefined' || typeof atts.exclude.lastminute === 'undefined' || !atts.exclude.lastminute) {
      window.debugBookingCalendar.push(this.debug)
      return false
    }

    var now = new Date()
    now.setMinutes( now.getMinutes() + ( atts.exclude.lastminute * 1) )

    if (now.getTime() > this.date.getTime()) {
      this.error = true;
      this.debug.status = "yes";
      this.debug.error = true;
    }
    window.debugBookingCalendar.push(this.debug)
    return false;
}



};

export default checkLastMinuteBooking;
