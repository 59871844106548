<template>

  <div v-if="data" class="successContainer" ref="flixSuccess">
    <div class="warningSignContainer">
      <img v-if="!$store.getters.save[2].validation" class="warningSign" src="https://cal.bookingflix.com/img/success.gif" />
      <svg v-else class="warningSign" :style="$getStyling(['fill'], 'accent')" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
      <path d="M985.2,890.2l-465.4-806c-4.1-7.1-11.7-11.5-20-11.5c-8.2,0-15.9,4.4-20,11.5L13.1,892.7c-4.1,7.1-4.1,15.9,0,23.1c4.1,7.1,11.7,11.5,20,11.5h933.6c0.1,0,0.2,0,0.2,0c12.7,0,23.1-10.3,23.1-23.1C990,899,988.2,894.1,985.2,890.2z M73,881.2l426.9-739.3l426.9,739.3H73z"/><path d="M476.8,375.3v330.8c0,12.7,10.3,23.1,23.1,23.1c12.7,0,23.1-10.3,23.1-23.1V375.3c0-12.7-10.3-23.1-23.1-23.1C487.2,352.2,476.8,362.6,476.8,375.3z"/><path d="M499.9,756c-15.8,0-28.6,12.8-28.6,28.6c0,15.8,12.8,28.6,28.6,28.6c15.8,0,28.6-12.8,28.6-28.6C528.5,768.9,515.7,756,499.9,756z"/></svg>
    </div>

    <div class="infoContainer" v-if="$store.getters.save[2].validation">
      <h1 class="hint" :style="$getStyling(['color'], 'accent')">{{ $store.getters.l.successfullSaved }}</h1>
      <div v-html="$store.getters.l.savedInfo"></div>
      <p>
        <a target="_blank" :href="'https://bookingflix.com/' + $store.getters.getLanguage + '/booking-details/' + $store.getters.save[2].user + '/' + md5($store.getters.save[2].ID) + '/index.html'" class="toDo">{{ $store.getters.l.savedLinkText }}</a>
      </p>
      <a class="addAppointment" href="#" @click.prevent="function () { newBooking() }" :style="$getStyling(['color'], 'accent')">{{ $store.getters.l.addAppointment }}</a>
    </div>
    <div class="infoContainer" v-else>
      <h1 class="hint" :style="$getStyling(['color'], 'accent')">{{ $store.getters.l.successHeader }}</h1>
      <div v-html="$store.getters.l.successHint.split('{email}').join('<b>' + $store.getters.save[2].email + '</b>')"></div>
      <p class="toDo" v-html="get_validation_msg()"></p>
      <p class="toDo small" v-html="get_validation_spam_msg()"></p>
      <div v-if="targetPage" class="hint" style="margin-bottom: 10px" :style="$getStyling(['color'], 'accent')">
        {{ $store.getters.l.targetPageInfo }}
      </div>
      <div v-else>
        <a class="addAppointment" href="#" @click.prevent="function () { newBooking() }" :style="$getStyling(['color'], 'accent')">{{ $store.getters.l.addAppointment }}</a>
      </div>
    </div>

  </div>

</template>

<script>

export default{
  props: {
    newBooking: Function
  },
  data(){

    return{
      data: false,
      open: false,
      transition: false,
      targetPage: false
    }
  },

  methods:{
    getOpenTargetPage () {
      var tp = this.$store.state.targetPage;
      if (!tp){
        return false;
      }

      if(tp.meta.method === 'targetPage' && tp.url) {
        this.targetPage = true;
        window.open(tp.url, '_self')
      }

    },
    get_validation_msg () {
      var data = JSON.parse(JSON.stringify(this.$store.getters.data))

      if (typeof data.verifyTime === 'undefined' || !data.verifyTime) {
        data.verifyTime = 2;
      }
      var txt = data.verifyTime;
      if (data.verifyTime === 1) {
        txt += ' ' + this.$store.getters.l.hour
      } else {
        txt += ' ' + this.$store.getters.l.hours
      }

      return this.$store.getters.l.successAction.split('{verifytime}').join(txt)
    },
    get_validation_spam_msg () {
      return this.$store.getters.l.successSpamHint
    },
    getDates(){
      this.data = {}
      var save = this.$store.getters.save[2];
      this.data.begin = new Date(save.begin.Y, save.begin.m - 1, save.begin.d, save.begin.H, save.begin.i, save.begin.s);
      this.data.end = new Date(save.end.Y, save.end.m - 1, save.end.d, save.end.H, save.end.i, save.end.s);


      //window.console.log(this.data);
    },
    md5(str) {
      var xl;

      var rotateLeft = function(lValue, iShiftBits) {
        return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
      };

      var addUnsigned = function(lX, lY) {
        var lX4, lY4, lX8, lY8, lResult;
        lX8 = (lX & 0x80000000);
        lY8 = (lY & 0x80000000);
        lX4 = (lX & 0x40000000);
        lY4 = (lY & 0x40000000);
        lResult = (lX & 0x3FFFFFFF) + (lY & 0x3FFFFFFF);
        if (lX4 & lY4) {
          return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
        }
        if (lX4 | lY4) {
          if (lResult & 0x40000000) {
            return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
          } else {
            return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
          }
        } else {
          return (lResult ^ lX8 ^ lY8);
        }
      };

      var _F = function(x, y, z) {
        return (x & y) | ((~x) & z);
      };
      var _G = function(x, y, z) {
        return (x & z) | (y & (~z));
      };
      var _H = function(x, y, z) {
        return (x ^ y ^ z);
      };
      var _I = function(x, y, z) {
        return (y ^ (x | (~z)));
      };

      var _FF = function(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(_F(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
      };

      var _GG = function(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(_G(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
      };

      var _HH = function(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(_H(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
      };

      var _II = function(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(_I(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
      };

      var convertToWordArray = function(str) {
        var lWordCount;
        var lMessageLength = str.length;
        var lNumberOfWords_temp1 = lMessageLength + 8;
        var lNumberOfWords_temp2 = (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64;
        var lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16;
        var lWordArray = new Array(lNumberOfWords - 1);
        var lBytePosition = 0;
        var lByteCount = 0;
        while (lByteCount < lMessageLength) {
          lWordCount = (lByteCount - (lByteCount % 4)) / 4;
          lBytePosition = (lByteCount % 4) * 8;
          lWordArray[lWordCount] = (lWordArray[lWordCount] | (str.charCodeAt(lByteCount) << lBytePosition));
          lByteCount++;
        }
        lWordCount = (lByteCount - (lByteCount % 4)) / 4;
        lBytePosition = (lByteCount % 4) * 8;
        lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
        lWordArray[lNumberOfWords - 2] = lMessageLength << 3;     lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
        return lWordArray;
      };

      var wordToHex = function(lValue) {
        var wordToHexValue = '',
          wordToHexValue_temp = '',
          lByte, lCount;
        for (lCount = 0; lCount <= 3; lCount++) {       lByte = (lValue >>> (lCount * 8)) & 255;
          wordToHexValue_temp = '0' + lByte.toString(16);
          wordToHexValue = wordToHexValue + wordToHexValue_temp.substr(wordToHexValue_temp.length - 2, 2);
        }
        return wordToHexValue;
      };

      var utf8_encode = function(string) {
          string = (string+'').replace(/\r\n/g, "\n").replace(/\r/g, "\n");

          var utftext = "";
          var start, end;
          var stringl = 0;

          start = end = 0;
          stringl = string.length;
          for (var n = 0; n < stringl; n++) {
              var c1 = string.charCodeAt(n);
              var enc = null;

              if (c1 < 128) {               end++;           } else if((c1 > 127) && (c1 < 2048)) {               enc = String.fromCharCode((c1 >> 6) | 192) + String.fromCharCode((c1 & 63) | 128);
              } else {
                  enc = String.fromCharCode((c1 >> 12) | 224) + String.fromCharCode(((c1 >> 6) & 63) | 128) + String.fromCharCode((c1 & 63) | 128);
              }
              if (enc != null) {
                  if (end > start) {
                      utftext += string.substring(start, end);
                  }
                  utftext += enc;
                  start = end = n+1;
              }
          }

          if (end > start) {
              utftext += string.substring(start, string.length);
          }

          return utftext;
      }

      var x = [],
        k, AA, BB, CC, DD, a, b, c, d, S11 = 7,
        S12 = 12,
        S13 = 17,
        S14 = 22,
        S21 = 5,
        S22 = 9,
        S23 = 14,
        S24 = 20,
        S31 = 4,
        S32 = 11,
        S33 = 16,
        S34 = 23,
        S41 = 6,
        S42 = 10,
        S43 = 15,
        S44 = 21;

      str = utf8_encode(str);
      x = convertToWordArray(str);
      a = 0x67452301;
      b = 0xEFCDAB89;
      c = 0x98BADCFE;
      d = 0x10325476;

      xl = x.length;
      for (k = 0; k < xl; k += 16) {
        AA = a;
        BB = b;
        CC = c;
        DD = d;
        a = _FF(a, b, c, d, x[k + 0], S11, 0xD76AA478);
        d = _FF(d, a, b, c, x[k + 1], S12, 0xE8C7B756);
        c = _FF(c, d, a, b, x[k + 2], S13, 0x242070DB);
        b = _FF(b, c, d, a, x[k + 3], S14, 0xC1BDCEEE);
        a = _FF(a, b, c, d, x[k + 4], S11, 0xF57C0FAF);
        d = _FF(d, a, b, c, x[k + 5], S12, 0x4787C62A);
        c = _FF(c, d, a, b, x[k + 6], S13, 0xA8304613);
        b = _FF(b, c, d, a, x[k + 7], S14, 0xFD469501);
        a = _FF(a, b, c, d, x[k + 8], S11, 0x698098D8);
        d = _FF(d, a, b, c, x[k + 9], S12, 0x8B44F7AF);
        c = _FF(c, d, a, b, x[k + 10], S13, 0xFFFF5BB1);
        b = _FF(b, c, d, a, x[k + 11], S14, 0x895CD7BE);
        a = _FF(a, b, c, d, x[k + 12], S11, 0x6B901122);
        d = _FF(d, a, b, c, x[k + 13], S12, 0xFD987193);
        c = _FF(c, d, a, b, x[k + 14], S13, 0xA679438E);
        b = _FF(b, c, d, a, x[k + 15], S14, 0x49B40821);
        a = _GG(a, b, c, d, x[k + 1], S21, 0xF61E2562);
        d = _GG(d, a, b, c, x[k + 6], S22, 0xC040B340);
        c = _GG(c, d, a, b, x[k + 11], S23, 0x265E5A51);
        b = _GG(b, c, d, a, x[k + 0], S24, 0xE9B6C7AA);
        a = _GG(a, b, c, d, x[k + 5], S21, 0xD62F105D);
        d = _GG(d, a, b, c, x[k + 10], S22, 0x2441453);
        c = _GG(c, d, a, b, x[k + 15], S23, 0xD8A1E681);
        b = _GG(b, c, d, a, x[k + 4], S24, 0xE7D3FBC8);
        a = _GG(a, b, c, d, x[k + 9], S21, 0x21E1CDE6);
        d = _GG(d, a, b, c, x[k + 14], S22, 0xC33707D6);
        c = _GG(c, d, a, b, x[k + 3], S23, 0xF4D50D87);
        b = _GG(b, c, d, a, x[k + 8], S24, 0x455A14ED);
        a = _GG(a, b, c, d, x[k + 13], S21, 0xA9E3E905);
        d = _GG(d, a, b, c, x[k + 2], S22, 0xFCEFA3F8);
        c = _GG(c, d, a, b, x[k + 7], S23, 0x676F02D9);
        b = _GG(b, c, d, a, x[k + 12], S24, 0x8D2A4C8A);
        a = _HH(a, b, c, d, x[k + 5], S31, 0xFFFA3942);
        d = _HH(d, a, b, c, x[k + 8], S32, 0x8771F681);
        c = _HH(c, d, a, b, x[k + 11], S33, 0x6D9D6122);
        b = _HH(b, c, d, a, x[k + 14], S34, 0xFDE5380C);
        a = _HH(a, b, c, d, x[k + 1], S31, 0xA4BEEA44);
        d = _HH(d, a, b, c, x[k + 4], S32, 0x4BDECFA9);
        c = _HH(c, d, a, b, x[k + 7], S33, 0xF6BB4B60);
        b = _HH(b, c, d, a, x[k + 10], S34, 0xBEBFBC70);
        a = _HH(a, b, c, d, x[k + 13], S31, 0x289B7EC6);
        d = _HH(d, a, b, c, x[k + 0], S32, 0xEAA127FA);
        c = _HH(c, d, a, b, x[k + 3], S33, 0xD4EF3085);
        b = _HH(b, c, d, a, x[k + 6], S34, 0x4881D05);
        a = _HH(a, b, c, d, x[k + 9], S31, 0xD9D4D039);
        d = _HH(d, a, b, c, x[k + 12], S32, 0xE6DB99E5);
        c = _HH(c, d, a, b, x[k + 15], S33, 0x1FA27CF8);
        b = _HH(b, c, d, a, x[k + 2], S34, 0xC4AC5665);
        a = _II(a, b, c, d, x[k + 0], S41, 0xF4292244);
        d = _II(d, a, b, c, x[k + 7], S42, 0x432AFF97);
        c = _II(c, d, a, b, x[k + 14], S43, 0xAB9423A7);
        b = _II(b, c, d, a, x[k + 5], S44, 0xFC93A039);
        a = _II(a, b, c, d, x[k + 12], S41, 0x655B59C3);
        d = _II(d, a, b, c, x[k + 3], S42, 0x8F0CCC92);
        c = _II(c, d, a, b, x[k + 10], S43, 0xFFEFF47D);
        b = _II(b, c, d, a, x[k + 1], S44, 0x85845DD1);
        a = _II(a, b, c, d, x[k + 8], S41, 0x6FA87E4F);
        d = _II(d, a, b, c, x[k + 15], S42, 0xFE2CE6E0);
        c = _II(c, d, a, b, x[k + 6], S43, 0xA3014314);
        b = _II(b, c, d, a, x[k + 13], S44, 0x4E0811A1);
        a = _II(a, b, c, d, x[k + 4], S41, 0xF7537E82);
        d = _II(d, a, b, c, x[k + 11], S42, 0xBD3AF235);
        c = _II(c, d, a, b, x[k + 2], S43, 0x2AD7D2BB);
        b = _II(b, c, d, a, x[k + 9], S44, 0xEB86D391);
        a = addUnsigned(a, AA);
        b = addUnsigned(b, BB);
        c = addUnsigned(c, CC);
        d = addUnsigned(d, DD);
      }

      var temp = wordToHex(a) + wordToHex(b) + wordToHex(c) + wordToHex(d);

      return temp.toLowerCase();
    }
  },
  beforeDestroy() {
    clearTimeout(this.transition);
  },
  mounted(){

    this.getDates();
    this.getOpenTargetPage();

    this.transition = setTimeout(function(){
      window.scrollTo({
        top: this.$refs.flixSuccess.offsetTop,
        left: 0,
        behavior: 'smooth'
      })
    }.bind(this), 2000)
  }
}

</script>

<style lang="sass" scoped>

  @import '@/style/variables.sass'

  .successContainer
    display: flex
    align-items: center
    flex-direction: row
    flex-direction: center
    .infoContainer
      flex: 1
      font-family: $font

      .hint
        display: block
        color: $active
      p
        margin: 20px 0
      b
        font-weight: bold

    .addAppointment
      font-family: $font
    .toDo
      color: $error
      font-weight: bold
      font-size: 16pt
      font-family: $font
      &.small
        font-size: 12pt
        font-weight: normal
  .warningSignContainer
    flex: 1
    max-width: 190px
    .warningSign
      width: 170px
      margin-right: 10px
      fill: $active

  @media(max-width: 500px)
    .successContainer
      display: block
    .warningSignContainer, .infoContainer
      flex: none
      width: 100%
      max-width: 100%
      float: none
      clear: both
      padding: 10px
      box-sizing: border-box
    .warningSignContainer
      text-align: center
      .warningSign
        width: 60%
</style>
