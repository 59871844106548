<template>
  <div>
    <div class="menu">
      <changeView :settings="{action: 'previous'}" :callback="function() { setDay(-1) }" />
      <a href="#" v-on:click.prevent="setView()" :title="$store.getters.l.backToDayView" v-html="getWeekday()+' '+getCurrDay()"></a>
      <changeView :callback="function() { setDay(1) }" />
      <a href="#" v-on:click.prevent="setView()" class="info">{{ $store.getters.l.backToDayView }}</a>
    </div>
  </div>
</template>
<script>
import changeView from '@/components/functions/changeView';
export default {
  components: {
    changeView
  },
  props: {
    day: Date,
    callback: Function,
    changeDate: Function
  },
  mounted () {

  },
  computed: {

  },
  data () {
    return {
    }
  },
  methods: {
    setView(){

      this.$store.commit("setService", false);

      if(typeof this.callback == "function")
        this.callback(false);
      else
        this.$store.commit('setView', -1);


    },
    getWeekday(){

        var weekday = this.day.getDay();

        if(weekday == 0)
          weekday = 7;

        var wd = this.$store.getters.weekdays[weekday -1];

        return wd[0]+wd[1]+".";

    },
    getCurrDay () {
      return this.$getNullsBefore(this.day.getDate())+"."+this.$getNullsBefore(this.day.getMonth() + 1)+"."+this.day.getFullYear()
    },
    setDay(add){
      var date = this.day;
      date.setDate( date.getDate() + add );

      this.day = date;
      this.changeDate(date);
    }
  }
}
</script>
  <style lang="sass" scoped>

    @import '@/style/variables.sass'
    @import '@/style/menu.sass'

  </style>
