<template>
  <div class="bookingNotPossible">
    <a @click.prevent="callback" v-if="typeof callback === 'function'" href="#">{{ $store.getters.l.bookingNotPossible }}</a>
    <span v-else>{{ $store.getters.l.bookingNotPossible }}</span>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    callback: Function
  }
}
</script>
<style lang="sass" scoped>

  @import '@/style/variables.sass'

  .bookingNotPossible
    text-align: center
    font-family: $font
    font-weight: bold
    font-size: 14pt
    color: $error
    a
      color: $error
      text-decoration: underline

</style>
