<template>
  <div>
    <calMenu :key="updateKey" :day="day" :callback="callback" :changeDate="function(date) { setDate(date) }" />
    <transition name="getDates">
      <div :key="updateKey">
        <component :is="component" :day="day" :callback="callback"/>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    default () { return import('@/components/view/day/hours/default.vue') },
    long () { return import('@/components/view/day/hours/long.vue') },
    calMenu () { return import('@/components/view/day/hours/menu.vue') },
  },
  props: {date: String, callback: Function},
  mounted () {
    this.getComponent()
  },
  computed: {

  },
  data () {
    return {
      component: 'default',
      day: this.$generateDate(this.$store.getters.date),
      updateKey: new Date().getTime()
    }
  },
  methods: {
    getComponent () {

    },
    setDate (date) {
      this.day = date
      this.updateKey = date.getTime()
    },
    getDate (){
      return this.$store.getters.date
    }
  }
}
</script>
<style lang="sass" scoped>
  @import '@/style/variables.sass'
  @import '@/style/hours.sass'
</style>
