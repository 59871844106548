<template>

  <div :class="{flixFocus: focused}" @mouseover="focused = true" @mouseleave="focused = false">
    <getLabel :data="data" :focus="focused" :key="focused" />
    <select v-model="input" @change.prevent="getClick">
      <option value="" v-if="data.required === true || data.required === 'true'">---</option>
      <option v-for="i in max" :key="i" :value="i">{{ i }}</option>
    </select>
    <div v-if="data.description" class="small" :class="{flixFocus: focused}">{{ data.description }}</div>
  </div>

</template>

<script>
export default{

  props: {data: Object, callback: Function, maxApplicants: [String, Number]},
  components: {
    getLabel () { return import('@/components/form/parts/label') }
  },
  data(){

    return{
      max: this.getMaxApplicants(),
      focused: false,
      input: this.getInput()
    }
  },

  methods: {
    getInput () {
      if (this.data.value) {
        return this.data.value
      }
      if (this.data.required) {
        return ''
      }
      return 1
    },
    getClick () {
      this.callback(this.input);
    },
    getMaxApplicants () {
      if (!this.data.values || this.data.values === 'auto') {
        return this.maxApplicants
      }
      if (this.data.values * 1 > 0 && this.data.values * 1 <= this.maxApplicants) {
        return this.data.values * 1
      } else {
        return this.maxApplicants
      }
    }
  },

  mounted(){
  }
}

</script>

<style lang="sass" scoped>
  @import '@/style/variables.sass'
  @import '@/style/form/label.sass'
  @import '@/style/form/select.sass'
</style>
