import Vue from 'vue'
import checkEnd from '../datepicker/checks/end.js'
import checkLastDayBooking from '../datepicker/checks/last_day_booking.js'
import checkLastMinuteBooking from '../datepicker/checks/last_minute_booking.js'
import checkPause from './checks/pause.js'
import checkExcludeDates from './checks/exclude_dates.js'
import checkExcludeBookings from './checks/exclude_bookings.js'
/*
import checkTotalStart from '../datepicker/checks/total_start.js'
import checkWeekday from '../datepicker/checks/weekday.js'
import checkTotalEnd from '../datepicker/checks/total_end.js'
import checkPeriodRange from '../datepicker/checks/period_range.js'
*/
var makeCalendar = function (store) {
  window.debugBookingCalendar = []
  this.dates = {}
  this.data = JSON.parse(JSON.stringify(store.getters.data))
  this.save = {}
  this.run = function () {

    var start_day = new Date()
    start_day = this.getStartDay(start_day)
    var days = this.getPeriodRange(start_day)
    days = this.getLastAppointmentSetting(start_day, days)

    for (var i=0; i<=days; i++) {
      this.getDay(start_day)
      start_day.setDate(start_day.getDate() + 1)
    }

  }

  this.setStartDay = function () {
    var d = Vue.prototype.$generateDate(store.getters.date)

    if (typeof this.save === 'undefined' || typeof d === 'undefined' || typeof d.getFullYear() === 'undefined' || typeof d.getMonth() === 'undefined') {
      this.setNewStartDate()
      return false
    }
    if (typeof this.save[d.getFullYear()] === 'undefined') {
      this.setNewStartDate()
      return false
    }

    if (typeof this.save[d.getFullYear()][((d.getMonth() * 1) + 1)] === 'undefined') {
      this.setNewStartDate()
    }
  }

  this.setNewStartDate = function () {

     if (Object.keys(this.save).length === 0 || typeof Object.keys(this.save)[0] === 'undefined') {
       return false
     }

    var d = this.save[Object.keys(this.save)[0]]
    d = d[Object.keys(d)[0]]
    d = d[Object.keys(d)[0]]
    d = d[Object.keys(d)[0]]
    d = d[Object.keys(d)[0]]
    store.commit("setDate", d.begin)
  }

  this.getDay = function(day) {
    var data = this.data
    var start_day = new Date(day)
    var start_weekday = Vue.prototype.$getWeekday(start_day)

    if(typeof data.include.weekdays[start_weekday] === 'undefined' || typeof data.include.weekdays[start_weekday][0] === 'undefined'){
      return false
    }

    data = this.checkService(data, start_weekday)
    data = this.checkNextAppointments(data, start_weekday)
    this.data = data
    start_day.setHours(data.include.weekdays[start_weekday][0].split(":")[0] * 1)
    start_day.setMinutes(data.include.weekdays[start_weekday][0].split(":")[1] * 1)
    start_day.setSeconds(0)
    start_day.setMilliseconds(0)

    // start_day.setMinutes(start_day.getMinutes() + 15)

    var end_day = new Date(start_day)
    end_day.setHours(data.include.weekdays[start_weekday][1].split(":")[0] * 1)
    end_day.setMinutes(data.include.weekdays[start_weekday][1].split(":")[1] * 1)
    end_day.setSeconds(0)
    end_day.setMilliseconds(0)

    var next_appointments = (data.next_appointments[start_weekday] * 1)
    if(!next_appointments && data.type === 'datepicker') { next_appointments = (data.include.weekdays[start_weekday][2] * 1) }
    var buffer = (data.include.buffer[start_weekday] * 1)
    if(!buffer) { buffer = 0 }

    buffer = (next_appointments + buffer)

    var difference = end_day.getTime() - start_day.getTime()
    difference = difference / (1000 * 60)
    difference = Math.floor(difference / buffer)


    var appointment_begin = new Date(start_day)
    var appointment_end = new Date(start_day)
    appointment_end.setMinutes(appointment_end.getMinutes() + (data.include.weekdays[start_weekday][2] * 1))

    this.weekday = start_weekday
    for(var i = 0; i<=difference; i++) {
      this.check({begin: new Date(appointment_begin), end: new Date(appointment_end)})
      appointment_begin.setMinutes(appointment_begin.getMinutes() + buffer)
      appointment_end.setMinutes(appointment_end.getMinutes() + buffer)
    }

  }

  this.checkNextAppointments = function (data, start_weekday){
    if (typeof data.next_appointments === 'undefined'){
      data.next_appointments = {}
    }

    if(typeof data.next_appointments[start_weekday] === 'undefined'){
      data.next_appointments[start_weekday] = data.include.weekdays[start_weekday][2]
    }

    return data
  }

  this.checkService = function (data, start_weekday) {

    if (typeof data.include.weekdays[start_weekday] !== 'undefined' && isNaN(parseInt(data.include.weekdays[start_weekday][2]))) {
      var time = store.getters.currentService.time * 1
      data.include.weekdays[start_weekday][2] = time
      data.include.weekdays[start_weekday][3] = time
      if (typeof data.next_appointments === 'undefined'){
        data.next_appointments = {}
      }
      if (typeof data.next_appointments[start_weekday] === 'undefined' || isNaN(parseInt(data.next_appointments[start_weekday]))){
        data.next_appointments[start_weekday] = time
      }
      if (typeof data.include.buffer === 'undefined'){
        data.include.buffer = {}
      }
      if (typeof data.include.buffer[start_weekday] === 'undefined'){
        data.include.buffer[start_weekday] = 0
      }
    }
    return data
  }

  this.check = function (dates) {

    if (this.checkTomorrow(dates)) {
       return false
    }
    if (this.checkEnd(dates)) {
      return false
    }
    if (this.checkStart(dates)) {
      return false
    }
    if (this.checkLastDay(dates)) {
      return false
    }
    if (this.checkLastMinuteBooking(dates)) {
      return false
    }
    if (this.checkStartDate(dates)) {
      return false
    }
    if (this.checkPause(dates)) {
      return false
    }
    if (this.checkExcludeDates(dates)) {
      return false
    }
    if (this.checkExcludeBookings(dates)) {
      return false
    }
    dates.weekday = this.weekday
    dates.maxApplicants = this.maxApplicants
    this.saveDate(dates)

  }
  this.saveDate = function (dates) {

    if(typeof this.save[dates.begin.getFullYear()] === 'undefined') {
      this.save[dates.begin.getFullYear()] = {}
    }
    if(typeof this.save[dates.begin.getFullYear()][dates.begin.getMonth() + 1] === 'undefined') {
      this.save[dates.begin.getFullYear()][dates.begin.getMonth() + 1] = {}
    }
    if(typeof this.save[dates.begin.getFullYear()][dates.begin.getMonth() + 1][dates.begin.getDate()] === 'undefined') {
      this.save[dates.begin.getFullYear()][dates.begin.getMonth() + 1][dates.begin.getDate()] = {}
    }
    if(typeof this.save[dates.begin.getFullYear()][dates.begin.getMonth() + 1][dates.begin.getDate()][dates.begin.getHours()] === 'undefined') {
      this.save[dates.begin.getFullYear()][dates.begin.getMonth() + 1][dates.begin.getDate()][dates.begin.getHours()] = {}
    }

    this.save[dates.begin.getFullYear()][dates.begin.getMonth() + 1][dates.begin.getDate()][dates.begin.getHours()][dates.begin.getMinutes()] = dates
  }
  this.getPeriodRange = function (start_date) {
    var d = new Date(start_date)

    var data = JSON.parse(JSON.stringify(this.data))
    if (typeof data.include.period === 'undefined') {
      data.include.period = [2, 'months']
    }

    switch( this.data.include.period[1] ){

      case "days":
        d.setDate(d.getDate() + ( (data.include.period[0] * 1) ));
      break;

      case "weeks":
        d.setDate(d.getDate() + (7 * (data.include.period[0] * 1) ));
      break;

      case "months":
      d.setMonth(d.getMonth() + (data.include.period[0] * 1) );
      break;

      case "years":
      d.setYear(d.getFullYear() + (data.include.period[0] * 1) );
      break;

    }

    var difference = d.getTime() - start_date.getTime()
    difference = difference / (((1000 * 60) * 60) * 24)
    difference = Math.floor(difference)

    return difference
  }

  this.getLastAppointmentSetting = function (start_date, diff1) {
    if (typeof this.data.end === 'undefined' || !this.data.end){
      return diff1
    }

    var d = Vue.prototype.$generateDate(this.data.end)
    d.setDate(d.getDate() + 1)

    var difference = d.getTime() - start_date.getTime()
    difference = difference / (((1000 * 60) * 60) * 24)
    difference = Math.floor(difference)

    if (difference < diff1) {
      return difference
    }

    return diff1
  }

  this.getStartDay = function (start_day) {
    if (typeof this.data.start !== 'undefined' || this.data.start){
      var newStartDay = Vue.prototype.$generateDate(this.data.start)
      if (newStartDay.getTime() > start_day.getTime()){
        return newStartDay
      } else {
        return start_day
      }
    }
    return start_day
  }

  this.checkStartDate = function (dates) {
    if (typeof this.data.start !== 'undefined' || this.data.start){
      if (dates.begin.getTime() < Vue.prototype.$generateDate(this.data.start).getTime()){
        return true
      }
    }
    return false
  }

  this.checkEnd = function (dates) {

    if (typeof this.data.end !== 'undefined' || this.data.end){
      if (dates.end.getTime() > Vue.prototype.$generateDate(this.data.end).getTime()){
        return true
      }
    }

    var check = new checkEnd(this.data);
    check.date = dates.end;
    check.check();

    return check.error
  }
  this.checkLastDay = function (dates) {

    if (this.data.type === 'datepicker' && dates.end.getDate() !== dates.begin.getDate()) {
      return true
    }

    var check = new checkLastDayBooking(this.data);
    check.generateEndDate = false
    check.date = dates.end;
    check.check();

    return check.error
  }
  this.checkLastMinuteBooking = function (dates) {
    var check = new checkLastMinuteBooking(this.data);
    check.date = dates.begin;
    check.check();

    return check.error
  }
  this.checkPause = function (dates) {
    var check = new checkPause(this.data);
    check.date = dates;
    check.check();

    return check.error
  }
  this.checkExcludeDates = function (dates) {
    var check = new checkExcludeDates(this.data);
    check.date = dates;
    check.check();

    return check.error
  }
  this.checkExcludeBookings = function (dates) {
    var check = new checkExcludeBookings(this.data);
    check.date = dates;
    check.check();

    if (!check.error) {
      this.maxApplicants = check.maxApplicants
    }
    return check.error
  }
  this.checkTomorrow = function (dates) {
    var end = new Date(dates.end)
    end.setMinutes(end.getMinutes() - 1)

    if (dates.begin.getDate() !== end.getDate()){
      return true
    }
    return false
  }
  this.checkStart = function (dates) {
    var start = new Date()

    if (start.getTime() > dates.begin.getTime()){
      return true
    }
    return false
  }

}

export default makeCalendar
