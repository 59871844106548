<template>

  <div>
    <component :is="data.subtype">{{ data.label }}</component>
  </div>

</template>

<script>

export default{

  props: {data: Object},

}

</script>

<style lang="sass" scoped>
  @import '@/style/variables.sass'
  @import '@/style/form/paragraph.sass'
</style>
