import Vue from 'vue';

var checkPause = function(atts){

  this.error = false;
  this.atts = atts;
  this.debug = {text: 'debugPause', status: "no", error: false}

  this.check = function(){


    var weekday = this.date.begin.getDay();

    if(weekday == 0)
      weekday = 7;

    weekday = weekday -1;

    if(typeof atts.exclude.weekdays[weekday] == 'object' && atts.exclude.weekdays[weekday] != null && typeof atts.exclude.weekdays[weekday].length){
      this.pausePerWeekday(weekday);
    }
    window.debugBookingCalendar.push(this.debug)
  }

  this.pausePerWeekday = function(weekday){

    JSON.parse(JSON.stringify(atts.exclude.weekdays[weekday])).forEach(function(slot){

      if(this.error)
        return false;

      var date = {
        begin: [this.date.begin.getHours(), this.date.begin.getMinutes()],
        end: [this.date.end.getHours(), this.date.end.getMinutes()]
      }

      var check = {
        begin: ""+Vue.prototype.$getNullsBefore(date.begin[0])+""+Vue.prototype.$getNullsBefore(date.begin[1])+"",
        end: ""+Vue.prototype.$getNullsBefore(date.end[0])+""+Vue.prototype.$getNullsBefore(date.end[1])+""
      }

      check.begin = check.begin * 1
      check.end = check.end * 1

      slot[0] = slot[0].split(":");
      slot[1] = slot[1].split(':');

      slot[0] = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), slot[0][0], slot[0][1]);
      slot[1] = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), slot[1][0], slot[1][1]);

      if (slot[0].getTime() != slot[1].getTime()) {
        slot[0].setMinutes(slot[0].getMinutes() * 1 + 1);
        slot[1].setMinutes(slot[1].getMinutes() * 1 - 1);
      }

      slot[0] = Vue.prototype.$getNullsBefore(slot[0].getHours()) + '' + Vue.prototype.$getNullsBefore(slot[0].getMinutes());
      slot[1] = Vue.prototype.$getNullsBefore(slot[1].getHours()) + '' + Vue.prototype.$getNullsBefore(slot[1].getMinutes());
      slot[0] *= 1
      slot[1] *= 1

      if( slot[0] <= check.end && slot[1] > check.begin ){

        this.error = true;
        this.debug.status = "yes";
        this.debug.error = true;
        return false;

      }

    }.bind(this));

  }

}

export default checkPause;
