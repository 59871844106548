<template>
  <div>

    <div class="menu">
      <changeView :settings="{action: 'previous'}" :callback="function() { setMonth(-1) }" />
      <a href="#" v-html="months[currMonth]+' '+currYear" :title="$store.getters.l.backToMonthView" v-on:click.prevent="$store.commit('setView', -1)"></a>
      <changeView :callback="function() { setMonth(1) }" />
      <a href="#" v-on:click.prevent="$store.commit('setView', -1)" class="info">{{ $store.getters.l.backToMonthView }}</a>
    </div>

      <transition name="getDates">
        <div :key="currMonth+''+currYear">
          <div class="dates" v-if="data && Object.keys(data).length">
              <a v-for="(day, index) in Object.keys(data)" :key="'week'+index"
                :style="getStyling()"
                @mouseenter="getHover"
                @mouseleave="removeHover"
                v-on:click="getClick(day)"
                :title="$getNullsBefore(day)+'.'+$getNullsBefore((currMonth))+'.'+currYear"
                :class="{
                  active: true,
                  currMonth: currMonth,
                  current: currYear+''+currMonth+''+day == new Date().getFullYear()+''+new Date().getMonth()+''+new Date().getDate()
                  }"
                >
                {{ $getNullsBefore(day) }}.{{ $getNullsBefore(currMonth) }}.{{ currYear }} <span>{{ $store.getters.l.weekdays[data[day][Object.keys(data[day])[0]][Object.keys(data[day][Object.keys(data[day])[0]])[0]].weekday] }}</span>
              </a>
            </div>
            <bookingNotPossible :callback="function(){ $store.commit('setView', -1) }" v-else/>
        </div>
      </transition>

    <changeTime />
    <debugTime />
  </div>
</template>

<script>

  import changeTime from '@/components/functions/changeTime';
  import debugTime from '@/components/functions/debugTime';
  import bookingNotPossible from '@/components/bookingNotPossible';
  import changeView from '@/components/functions/changeView';

  export default{
    props: {date: String},
    components: {changeTime, debugTime, bookingNotPossible, changeView},

    data(){

      var date = this.date.split("-");

      return{
        currMonth: date[1] * 1,
        currYear: date[0] * 1,
        weekdays: this.$store.getters.l.weekdays,
        months: this.$store.getters.l.monthsNames,
        data: false,
        active_count: 0
      }
    },
    watch: {
      currMonth () { this.data = {}; this.getData() }
    },
    computed:{

      getDaysPerWeek(){
        var days = this.getDays();

        var i = 0;
        var j = 0;
        var r = [];

        Object.keys(days).forEach(function(key){

          j++;

          if(typeof r[i] == "undefined")
            r[i] = {};

          r[i][key] = days[key];

          if( j % 7 == 0 ){
            i++;
          }
        });

        return r;
      }
    },

    methods:{
      getData () {
        this.$makeCalendar()
        this.data = []

        var data = this.$store.getters.approvedDates
        if (typeof data === 'undefined' || typeof data[this.currYear] === 'undefined' || data[this.currYear][this.currMonth * 1] === 'undefined') {
          return false
        }
        this.data = data[this.currYear][this.currMonth * 1]

      },
      getStyling () {
        return this.$getStyling(['border-bottom-color'], 'accent')
      },
      getHover (event) {
        var bg = this.$getStyling(['background-color'], 'accent')

        if (bg !== false && event.target.classList.contains('active')) {
          event.target.style['background-color'] = bg['background-color']
        }
      },
      removeHover (event) {
        var bg = this.$getStyling(['background-color'], 'accent')
        if (bg !== false && event.target.classList.contains('active')) {
          event.target.style['background-color'] = 'inherit'
        }
      },
      getClick(day){

        var date = this.$generateDate(this.currYear + '-' + this.$getNullsBefore((this.currMonth)) + '-' + this.$getNullsBefore(day))
        this.$store.commit('setDay', date);
        this.$store.commit('setView', 1);

      },
      isActive(index2){
        return this.$checkDate(index2);
      },
      setMonth(add){

        var daysOfMonth = new Date();
        daysOfMonth.setDate(1);
        daysOfMonth.setYear(this.currYear);
        daysOfMonth.setMonth(((this.currMonth * 1) + (add * 1)));


        this.currMonth = daysOfMonth.getMonth();
        this.currYear = daysOfMonth.getFullYear();

        if(!this.currMonth){

          this.currMonth = 12;
          this.currYear--;

        }

      },

      getDays(){

        var date = {};

        date = Object.assign(date, this.getDatesCurrentMonth());

        return date;



      },


      getDatesNextMonth(){

        var daysOfMonth = new Date();
        daysOfMonth.setDate(1);
        daysOfMonth.setYear(this.currYear);
        daysOfMonth.setMonth(this.currMonth);
        daysOfMonth.setDate(0);

        var weekday = daysOfMonth.getDay();

        if(weekday == 0)
          weekday = 7;

        var r = {};

        var j = 1;
        for( var i = weekday; i< 7; i++ ){

          var currDate = new Date();
          currDate.setDate(1);
          currDate.setYear(this.currYear);
          currDate.setMonth(this.currMonth);
          currDate.setDate(j);
          j++;

          r[currDate.getFullYear()+"-"+this.$getNullsBefore(currDate.getMonth()+1)+"-"+this.$getNullsBefore(currDate.getDate())] = [currDate, false];
        }

        return r;

      },

      getDatesCurrentMonth(){

        var daysOfMonth = new Date();
        daysOfMonth.setDate(1);
        daysOfMonth.setYear(this.currYear);
        daysOfMonth.setMonth(this.currMonth);
        daysOfMonth.setDate(0);

        var r = {};

        for(var i = 1; i<=daysOfMonth.getDate(); i++){
          var currDate = new Date();
          currDate.setDate(1);
          currDate.setYear(this.currYear);
          currDate.setMonth(this.currMonth-1);
          currDate.setDate(i * 1);

          r[currDate.getFullYear()+"-"+this.$getNullsBefore(currDate.getMonth()+1)+"-"+this.$getNullsBefore(currDate.getDate())] = [currDate, true];
        }

        return r;
      },

      getDatesLastMonth(){

        var firstDayOfMonth = new Date();
        firstDayOfMonth.setDate(1);
        firstDayOfMonth.setYear(this.currYear);
        firstDayOfMonth.setMonth(this.currMonth-1);

        var weekday = firstDayOfMonth.getDay();

        if(weekday == 0)
          weekday = 7;

        var r = {};

        for(var i = ( weekday * -1) +2; i<=0; i++){

            var currDate = new Date();
            currDate.setDate(1);
            currDate.setYear(this.currYear);
            currDate.setMonth(this.currMonth -1);
            currDate.setDate((i * 1));

            r[currDate.getFullYear()+"-"+this.$getNullsBefore(currDate.getMonth()+1)+"-"+this.$getNullsBefore(currDate.getDate())] = [currDate, false];

        }

        return r;
      },

    },


    mounted() {
      this.getDays();
      this.getData();
    }
  }

</script>

<style lang="sass" scoped>

  @import '@/style/variables.sass'
  @import '@/style/menu.sass'
  @import '@/style/month.sass'

  td > div
    &.active
      display: block

</style>
