<template>
  <div class="input">
    <h3>{{ $store.getters.l.myName }}</h3>
    <input type="text" v-model="name"/>
    <div v-if="$store.getters.data.comments.toString() == '0'">
      <h3>{{ $store.getters.l.myComment }}</h3>
      <textarea v-model="comment"/>
    </div>
    <div class="btn-grp">
      <a href="#" class="commitment" @click.prevent="setBooking(1)">{{ $store.getters.l.commitments }}</a>
      <a href="#" class="cancellation" @click.prevent="setBooking(0)">{{ $store.getters.l.cancellation }}</a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      name: '',
      comment: ''
    }
  },
  methods: {
    setBooking (commitment) {
      if (!this.name.trim().length) {
        return false
      }
      var d = new Date()
      var data = {name: this.name.trim(), commitment: commitment, comment: this.comment.trim(), date: d.getFullYear() + '-' + d.getMonth() + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes()}

      var callback = this.$store.getters.callback;
      callback.saveData = { ID: this.$store.getters.data.ID, data: data }
      callback.setCallback(function(){ this.$store.commit('setBooking', data) }.bind(this));
      callback.loadAxios(function () {
        callback.sendPrivateData()
      });
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  @import '@/style/variables.sass'
  @import '@/style/form/label.sass'
  @import '@/style/form/text.sass'
  @import '@/style/form/private.sass'
</style>
