<script>
import def from '@/components/view/day/hours/default.vue'
export default {
  extends: def,
  components: {},
  props: {},
  mounted () {

  },
  methods: {
    getData () {
      var days = this.$getDateInfo(this.day)

      var start = this.$generateDate(this.day.getFullYear() + '-' +this.$getNullsBefore((this.day.getMonth() + 1))+ '-' + this.$getNullsBefore(this.day.getDate()) + ' ' + days.weekday[0])
      var end = this.$generateDate(this.day.getFullYear() + '-' +this.$getNullsBefore((this.day.getMonth() + 1))+ '-' + this.$getNullsBefore(this.day.getDate()) + ' ' + days.weekday[1])
      var check = []
      var time = new Date(start)
      for (var i = start.getHours(); i <= end.getHours(); i++){

        if (this.day.getDate() === time.getDate() && this.day.getMonth() === time.getMonth()){
          if (check.indexOf(time.getHours()) === -1 && this.checkDate(time.getMinutes(), time.getHours())){
            this.data.push({
              title: this.getWeekday()+', '+this.currDay+', '+this.$getNullsBefore(time.getHours())+'-'+this.$getNullsBefore(time.getMinutes())+' '+this.oClock,
              time: this.$getNullsBefore(time.getHours()),
              i: time.getHours(),
              j: time.getMinutes()
            })
            check.push(time.getHours())
          }
        }
        if (days.weekday[3] < days.weekday[2]) {
          time.setMinutes(time.getMinutes() + days.weekday[3])
        } else {
          time.setMinutes(time.getMinutes() + days.weekday[2])
        }
      }

    },
    getClick(i,j){

      this.$store.commit('setHour', new Date( this.day.getFullYear(), (this.day.getMonth()), this.day.getDate(), i , j));
      this.$store.commit('setView', 1);

    },
  }
}
</script>
