<template>

  <div :class="{flixFocus: focused}">
    <getLabel :data="data" :focus="focused" :key="focused" />
    <ul class="select">
    <li v-for="(term) in ['yes']" :class="{active: active[term]}" :key="term" @click="getClick(term)">
      <checkCheckbox v-if="active[term]"/> <emptyCheckbox v-else/> <span>{{ $store.getters.l[term] }}</span>
    </li>
    </ul>
    <div v-if="data.description">
      <a href="#" class="viewTerms" v-html="$store.getters.l.terms" @click.prevent="open = !open"></a>
      <div class="viewTerms" v-if="open" v-html="data.description"></div>
    </div>
  </div>

</template>

<script>
export default{

  props: {data: Object, callback: Function},
  components: {
    getLabel () { return import('@/components/form/parts/label') },
    emptyCheckbox () { return import('@/assets/glyphicons-basic-154-square-empty') },
    checkCheckbox () { return import('@/assets/glyphicons-basic-844-square-check') }
  },
  data(){

    return{
      focused: false,
      input: this.data.value,
      open: false,
      active: {"yes": false}
    }
  },
  methods: {
    getClick (term) {
      this.active = {"no": false, "yes": false}
      this.active[term] = true

      this.callback(this.$store.getters.l[term]);
    }
  }
}

</script>

<style lang="sass" scoped>
  @import '@/style/variables.sass'
  @import '@/style/form/label.sass'
  @import '@/style/form/select.sass'
  @import '@/style/form/agb.sass'
</style>
