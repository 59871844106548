<template>
  <div>

    <div class="menu">
      <changeView :settings="{action: 'previous'}" :callback="function() { setYear(-1) }" />
      <a href="#" v-on:click.prevent v-html="currYear+'-'+(currYear+9)"></a>
      <changeView :callback="function() { setYear(1) }" />
    </div>

    <transition name="getDates">
      <div class="dates" :key="currYear" v-if="data">
        <a v-for="(date, index) in data" :key="index"
        href="#"
        @mouseenter="getHover"
        @mouseleave="removeHover"
        class="active"
        v-on:click.prevent="setClick(date)"
        :title="date"
        :class="{
          current: false
        }"
        :style="getStyling()"
        >{{ date }}</a>
      </div>
      <bookingNotPossible v-else />
    </transition>

    <changeTime />
    <debugTime />
  </div>
</template>

<script>

  import changeTime from '@/components/functions/changeTime';
  import debugTime from '@/components/functions/debugTime';
  import bookingNotPossible from '@/components/bookingNotPossible';
  import changeView from '@/components/functions/changeView';

  export default{

    props: {date: String},
    components: {changeTime, debugTime, bookingNotPossible, changeView},

    data(){

      var date = this.date.split("-");

      var startYear = date[0] * 1+"";
      startYear = startYear[0]+""+startYear[1]+""+startYear[2]+"0";

      return{
        rows: Object.keys([1]),
        columns: Object.keys([1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ]),
        currYear: startYear * 1,
        data: false,
        active_count: 0
      }
    },
    watch: {
      currYear () { this.data = false; this.getData() }
    },
    methods:{
      getData () {
        this.$makeCalendar()

        var data = this.$store.getters.approvedDates
        if (typeof data === 'undefined') {
          return false
        }

        var ret = []
        Object.keys(data).forEach(function (year) {
          if (year >= this.currYear && year <= (this.currYear + 9) ){
            ret.push(year)
          }
        }.bind(this))

        if (ret.length) {
          this.data = ret
        }
      },
      getStyling () {
        return this.$getStyling(['border-bottom-color'], 'accent')
      },
      getHover (event) {
        var bg = this.$getStyling(['background-color'], 'accent')

        if (bg !== false && event.target.classList.contains('active')) {
          event.target.style['background-color'] = bg['background-color']
        }
      },
      removeHover (event) {
        var bg = this.$getStyling(['background-color'], 'accent')
        if (bg !== false && event.target.classList.contains('active')) {
          event.target.style['background-color'] = 'inherit'
        }
      },
      setClick(date){

        this.$store.commit('setYear', (date));
        this.$store.commit('setView', 1);

      },
      isActive(i,j){

        return this.$checkDate(this.currYear + i + i + i + j);

      },
      setYear(add){
        this.currYear = (this.currYear * 1) + (add * 10);
      }
    },
    mounted () {
      this.getData()
    }

  }

</script>

  <style lang="sass" scoped>

    @import '@/style/variables.sass'
    @import '@/style/year.sass'
    @import '@/style/menu.sass'

    .menu
      a
        &:nth-child(2)
          text-decoration: none
          cursor: default

  </style>
