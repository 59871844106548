<template>
  <div class="legendContainer">
    <div class="legend">{{ $store.getters.l.legend }}</div>
    <div class="bookingDate" :style="$getStyling(['border-color'], 'accent')">{{ $store.getters.l.bookingDate }}</div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  @import '@/style/variables.sass'
  @import '@/style/legend.sass'
</style>
