import Vue from 'vue';

var checkTotalStart = function(atts){

  this.error = false;
  this.atts = atts;
  this.debug = {text: 'debugTotalStart', status: "no", error: false}

  this.check = function(){


    if( typeof atts.start == 'string' && atts.start){
      this.checkStartDate();
    }

  }

  this.checkStartDate = function(){


    var start = atts.start.split(" ");
    var Ymd = start[0].split("-");
    var Hi = start[1].split(":");



    var date1 = ""+Ymd[0]+""+Vue.prototype.$getNullsBefore(Ymd[1]*1)+""+Vue.prototype.$getNullsBefore(Ymd[2]*1)+""+Vue.prototype.$getNullsBefore(Hi[0]*1)+""+Vue.prototype.$getNullsBefore(Hi[1]*1)+"";

    var date2 = ""+this.date.getFullYear()+""+Vue.prototype.$getNullsBefore(this.date.getMonth()+1)+""+Vue.prototype.$getNullsBefore(this.date.getDate())+""+Vue.prototype.$getNullsBefore(this.date.getHours())+""+Vue.prototype.$getNullsBefore(this.date.getMinutes());

    if( (date2 * 1) < (date1 *1) ){
      this.error = true;
      this.debug.error = true;
      this.debug.status = 'yes';
    }

    window.debugBookingCalendar.push(this.debug)

  }

}

export default checkTotalStart;
