<template>

  <div :class="{flixFocus: focused}" :key="active.length" @mouseover="focused = true" @mouseleave="focused = false">
    <getLabel :data="data" :focus="focused" :key="focused" />
    <ul class="select">
    <li v-for="(term, index) in data.values" :class="{active: active[index]}" :key="index+key" @click="getClick(index)">
      <checkCheckbox v-if="active[index]"/> <emptyCheckbox v-else/> <span>{{ term.label }}</span>
    </li>
    </ul>
    <div v-if="data.description" class="small" :class="{flixFocus: focused}">{{ data.description }}</div>
  </div>

</template>

<script>
export default{

  props: {data: Object, callback: Function},
  components: {
    getLabel () { return import('@/components/form/parts/label') },
    emptyCheckbox () { return import('@/assets/glyphicons-basic-154-square-empty') },
    checkCheckbox () { return import('@/assets/glyphicons-basic-844-square-check') }
  },
  data(){

    return{
      active: {},
      focused: false,
      key: new Date().getTime()
    }
  },

  methods: {
    getDefaultActive () {

      if (typeof this.data.value === 'string' && this.data.value) {
        var a = {}
        JSON.parse(JSON.stringify(this.data.values)).forEach(function (v, k) {
          a[k] = false
          if (v.value === this.data.value) {
            a[k] = true
          }
        }.bind(this))
        this.active = a
        this.key = new Date().getTime()
      } else{
        Object.keys(this.data.values).forEach(function(k){
          this.active[k] = false;
        }.bind(this));
      }
    },
    getActive(index){

      return this.active[index];
    },

    getClick(index){

      Object.keys(this.active).forEach(function(k){
        this.active[k] = false;
      }.bind(this));

      this.active[index] = true;
      this.key = new Date().getTime();


      this.callback(this.data.values[index].value);

    },


  },

  mounted(){
    this.getDefaultActive()
  }
}

</script>

  <style lang="sass" scoped>
    @import '@/style/variables.sass'
    @import '@/style/form/label.sass'
    @import '@/style/form/select.sass'
  </style>
