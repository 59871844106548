<template>

  <div>
    <p class="paragraph" v-html="data.description"></p>
  </div>

</template>

<script>

export default{

  props: {data: Object},

}

</script>

<style lang="sass" scoped>
  @import '@/style/variables.sass'
  @import '@/style/form/paragraph.sass'
</style>
