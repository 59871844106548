<template>
  <div class="bookingEntry">
    <h3>{{ $store.getters.l.privateSuccessHeader }}</h3>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  @import '@/style/variables.sass'
  @import '@/style/form/private.sass'
  .bookingEntry
    h3
      width: 100%
      display: block
      text-align: center
</style>
