<template>
  <div v-if="bookings && bookings.length" class="commitmentCancellation">
    <h3 class="commitment" v-if="sum.commitment">{{ $store.getters.l.commitments }} ({{ sum.commitment }})</h3>
    <h3 class="cancellation" v-if="sum.cancellation">{{ $store.getters.l.cancellation }} ({{ sum.cancellation }})</h3>
    <div class="bookingEntry">
      <div v-for="(booking, index) in bookings" :key="index" :class="{notCommited: booking.commitment != 1}">
        <span v-if="$store.getters.data.exclude.anonym.toString() == '1'">{{ anonymName(booking.name) }}</span>
        <span v-else>{{ booking.name }}</span>
      </div>
    </div>
    <div class="anonymInfo" v-if="$store.getters.data.exclude.anonym.toString() == '1'">{{ $store.getters.l.anonymInfo }}</div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      bookings: this.$store.getters.privateBookings,
      sum: {
        commitment: 0,
        cancellation: 0
      }
    }
  },
  methods: {
    anonymName (name) {
      var newName = name[0]
      for(var i = 1; i < name.length; i++) {
        newName += 'X'
      }
      return newName;
    }
  },
  mounted () {
    this.$store.getters.privateBookings.forEach(function (booking) {
      if (booking.commitment * 1 === 1) {
        this.sum.commitment += 1
      } else {
        this.sum.cancellation += 1
      }
    }.bind(this))
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  @import '@/style/variables.sass'
  @import '@/style/form/label.sass'
  @import '@/style/form/text.sass'
  @import '@/style/form/private.sass'
  h3
    margin-top: 60px
</style>
