<template>

  <div :class="{flixFocus: focused}">
    <getLabel :data="data" :focus="focused" :key="focused" />
    <textarea @change="callback(input)" v-model="input" :placeholder="$store.getters.l.pleaseFillOut" type="data.type" :id="data.id" @focusin="focused = true" @focusout="focused = false" :class="{flixFocus: focused}"/>
    <div v-if="data.description" class="small" :class="{flixFocus: focused}">{{ data.description }}</div>
  </div>

</template>

<script>

import getLabel from '@/components/form/parts/label';

export default{

  props: {data: Object, callback: Function},
  components: {getLabel},

  data(){

    return{
      focused: false,
      input: this.data.value
    }
  }
}

</script>

<style lang="sass" scoped>
  @import '@/style/variables.sass'
  @import '@/style/form/label.sass'
  @import '@/style/form/text.sass'
</style>
