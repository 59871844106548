<template>
  <div class="calNav" v-if="Object.keys($store.state.multiCalendar).length">
    <a href="#" :class="{'active': $store.state.data.ID == id}" @click.prevent="changeCalendar(id)" v-for="(calendar, id) in $store.state.multiCalendar" :key="id">{{ calendar.title }}</a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    callback: Function
  },
  data () {
    return {

    }
  },
  methods: {
    changeCalendar (id) {

      var data = JSON.parse(JSON.stringify(this.$store.state.multiCalendar[id]))
      data.ID = id

      if (this.$store.getters.data.ID === id) {
        return false
      }

      this.$store.commit("setSettings", {'currentView': 0, 'viewBox': 'calendar'});

      var parent = this.$parent.$parent

      parent.renderCalendar({data: data, settings: {
        save: false,
        callback: this.$store.getters.callback,
        date: "",
        currentService: {},
        approvedDates: {},
        saveFormData: {},
        currentEvent: {},
        info: {
          startClick: 0,
          click: 0
        }
      }})
      parent.calculateAlternativeListView()

      if (this.$store.getters.viewBox === 'calendar') {
        this.$store.commit("setSettings", {'currentView': 2});
      }

    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  @import '@/style/variables.sass'

  .calNav
    display: flex
    margin-bottom: 20px
    align-items: center
    flex-direction: row
    flex-wrap: wrap
    gap: 5px
    margin-top: 10px
    a
      font-family: $font
      font-weight: bold
      flex: 25%
      text-align: center
      margin-bottom: 5px
      padding: 5px
      border: 1px solid lightgrey
      color: black
      text-decoration: none
      &:last-of-type
        margin-right: 0
      &.active
        background-color: $active
        color: white
  @media (max-width: 500px)
    .calNav
      a
        flex: 100%
</style>
