import Vue from 'vue';

var checkWeekday = function(atts){

  this.error = true;
  this.atts = atts;
  this.debug = {text: 'debugWeekday', status: "yes", error: true}

  this.check = function(){

    var weekday = this.date.getDay();

    if(weekday == 0)
      weekday = 7;

    weekday = weekday -1;

    if(typeof atts.include.weekdays[weekday] == 'object' &&  atts.include.weekdays[weekday].length){

      if(this.settings.wholeDay){
        this.error = false;
        this.debug.error = false;
        this.debug.status = 'no';
        window.debugBookingCalendar.push(this.debug)
        return false;
      }

      this.checkInsideWeekday(weekday);
    }
    window.debugBookingCalendar.push(this.debug)

  }

  this.checkInsideWeekday = function(weekday){

    var date = [this.date.getHours(), this.date.getMinutes()];

    var check = ""+Vue.prototype.$getNullsBefore(date[0])+""+Vue.prototype.$getNullsBefore(date[1])+"";

    var slot = atts.include.weekdays[weekday];


    if( check * 1 >= slot[0].replace(":", "") * 1 && check <= slot[1].replace(":", "") * 1 ){
      this.error = false;
      this.debug.error = false;
      this.debug.status = 'no';
      return false;
    }

  }

}

export default checkWeekday;
