import checkEnd from '../datepicker/checks/end.js'
import checkExcludeDates from '../datepicker/checks/exclude_dates.js'
import checkExcludeBookings from '../datepicker/checks/exclude_bookings.js'
import checkLastMinuteBooking from '../datepicker/checks/last_minute_booking.js'

var eventPickerDates = function(atts){

  this.error = false;
  this.debug = {text: 'debugDates', status: "no", error: false}

  this.checkDate = function(){

    this.check(this.begin);

    if(this.error){
      this.debug.error = true;
      this.debug.status = 'yes';
      window.debugBookingCalendar.push(this.debug)
      return false;
    }

    this.check(this.end);

    if(this.error){
      this.debug.error = true;
      this.debug.status = 'yes';
    }
    window.debugBookingCalendar.push(this.debug)
  };

  this.check = function(date){

    this.date = date;

    this.checkLastMinuteBooking();

    if(this.error)
      return this.error;

    this.checkEnd();

    if(this.error)
      return false;

    this.checkExcludeDates();

    if(this.error)
      return false;

    this.checkExcludeBookings();

    if(this.error)
      return false;

  };

  this.checkExcludeDates = function(){

    var check = new checkExcludeDates(atts);
    check.date = this.date;
    check.check();

    if(check.error)
      this.error = check.error;

  };

  this.checkExcludeBookings = function(){

    var check = new checkExcludeBookings(atts);
    check.date = this.date;
    check.check();

    if(check.error)
      this.error = check.error;

  };

  this.checkLastMinuteBooking = function(){

    var check = new checkLastMinuteBooking(atts);
    check.date = this.date;
    check.check();

    if(check.error)
      this.error = check.error;

  };

  this.checkEnd = function(){


    var check = new checkEnd(atts);
    check.date = this.date;
    check.check();

    if(check.error)
      this.error = check.error;


  };

};

export default eventPickerDates;
