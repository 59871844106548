import datePickerRules from './calculate_events.js'
import eventPickerRules from './eventpicker_events.js'
import makeCalendar from './makeCalendar/index.js'


export default {

  install (Vue) {
    Vue.prototype.$getStyling = function (a, b) {
      if (typeof this.$store.getters.data.styling === 'object') {
        var ret = {}
        a.forEach(function (v) {
          ret[v] = this.$store.getters.data.styling[b]
        }.bind(this))

        return ret
      }
      return false
    }
    Vue.prototype.$getWeekday = function(day) {
      var weekday = day.getDay();

      if( weekday == 0 )
        weekday = 7;

      weekday = weekday - 1;
      return weekday;
    }
    Vue.prototype.$getNextDate = function(day, data){
      var weekday = day.getDay();

      if( weekday == 0 )
        weekday = 7;

      weekday = weekday - 1;

      if(typeof data.next_appointments === 'undefined' || typeof data.next_appointments[weekday] === 'undefined') {

        if(typeof data.include.weekdays === 'object' && typeof data.include.weekdays[weekday] !== 'undefined' && !isNaN(parseInt(data.include.weekdays[weekday][2])) ){
          return data.include.weekdays[weekday][2] * 1
        }
        return 15
      }
      if(isNaN(parseInt(data.next_appointments[weekday]))){
        return 15
      }

      return data.next_appointments[weekday] * 1
    }
    Vue.prototype.$generateDate = function(dateString){

      dateString = dateString.toString();

      dateString = dateString.split(" ");

      var Ymd = dateString[0].split( "-" );
      var His = [0, 0, 0];

      if(typeof dateString[1] == "string")
        His = dateString[1].split(":");

      return new Date(Ymd[0], (Ymd[1] * 1 - 1), Ymd[2], His[0], His[1], 0);
    }

    Vue.prototype.$cleanDates = function(){

      var r = new datePickerRules(this.$store.getters.data).cleanApprovedDates();
      return r;
    }

    Vue.prototype.$makeCalendar = function(){
      if (Object.keys(this.$store.getters.approvedDates).length > 0){
        return false
      }
      var m = new makeCalendar(this.$store)
      m.run()
      m.setStartDay()
      this.$store.commit("setSettings", {approvedDates: m.save})
    }

    Vue.prototype.$checkDate = function(date, end){
      window.debugBookingCalendar = []

      var r = new datePickerRules(this.$store.getters.data);
      r.setAppointmentEnd = end;

      var checkDate = r.getCheckDate(date);

      if (typeof r.approvedApplicants === 'object') {
        this.$store.commit("approvedApplicants", r.approvedApplicants);
      }

      return checkDate
    }

    Vue.prototype.$eventpickerDate = function(date){
      window.debugBookingCalendar = []

      var r = new eventPickerRules(this.$store.getters.data).getCheckDate(date);

      return r;
    }

    Vue.prototype.$getNullsBefore = function (nbr) {

      if(nbr *1 >= 10)
        return nbr;

        return 0+""+nbr;
    }

    Vue.prototype.$getServiceTime = function (time) {

      var hours = time / 60 /// make hours

      if (hours === 1) {
        return hours + ' ' + this.$store.getters.l.hour
      }
      if (hours > 1) {
        return hours + ' ' + this.$store.getters.l.hours
      }

      if (hours < 1) {
        return time + ' ' + this.$store.getters.l.minutes
      }

    }

    Vue.prototype.$getEndDate = function (date, data, service) {

      if( typeof data == "undefined" )
        data = this.$store.getters.data;

      if( typeof service == "undefined" )
        service = this.$store.getters.currentService;

      var weekday = date.getDay();
      var endTime = "";
      var end = "";

      if( weekday == 0 )
        weekday = 7;

      weekday = weekday - 1;

      if( typeof data.include.weekdays == "undefined" || typeof data.include.weekdays[weekday] == "undefined")
        return date;

      if( typeof service == "object" ){
        if( service.time < 15 ) {
          endTime = service.time * 60;
        } else {
          endTime = service.time * 1;
        }
      }

      if( !endTime && isNaN(parseInt(data.include.weekdays[weekday][2])) )
        return date;

      if( !endTime )
        endTime = parseInt(data.include.weekdays[weekday][2]);

      end = new Date( date.valueOf() );
      end.setMinutes( end.getMinutes() * 1 + endTime );

      return end;

    };

    Vue.prototype.$nl2br = function (str, is_xhtml) {
      if (typeof str === 'undefined' || str === null) {
        return ''
      }
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>'
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
    };

    Vue.prototype.$getDateInfo = function (day) {
      var weekday = day.getDay()

      if(weekday == 0)
        weekday = 7;

      weekday = weekday-1

      var data = this.$store.getters.data
      var r = {}

      if (typeof data.include.weekdays === 'undefined' || typeof data.include.weekdays[weekday] === 'undefined') {
        r = { weekday: {2: 60, 3: 60 }}
        return r
      }

      if (isNaN(parseInt(data.include.weekdays[weekday][2]))) {
        r = { weekday: {2: 60, 3: 60 }}
        return r
      }

      var next_appointments = this.$getNextDate(day, data)

      r = { weekday: data.include.weekdays[weekday], buffer: data.include.buffer[weekday] }
      r.weekday[3] = next_appointments * 1

      return r
    }

  }
}
