//import Vue from 'vue'

var calculateView = function (store) {

  this.data = false

  this.run = function () {

    if( !this.data ||  typeof this.data.include === 'undefined' || this.data.type !== "datepicker" )
      return false;

    this.check = {service: 0, default: 0};

    Object.values(this.data.include.weekdays).forEach(function(v){

      if(typeof v[2] === 'undefined') {
        return true
      }

      if (isNaN(parseInt(v[2]))){
        this.check.service += 1
      } else {
        this.check.default += 1
      }

    }.bind(this));

  }

  this.setView = function () {

    if( !this.data ||  typeof this.data.include === 'undefined' || this.data.type !== "datepicker" )
      return false;

    if (this.check.service) {
      store.commit("setSettings", {'currentView': 0, 'viewBox': 'list'});
    }
  }

}

export default calculateView
