import Vue from 'vue'
import makeStore from '../store'
import datepickerHours from './datepicker/hours.js'

var store = makeStore();


var datePickerRules = function(data){

  var atts = data;

  this.cleanApprovedDates = function(){
    store.commit('cleanApprovedDates', {})
  },

  this.getCheckDate = function(dateString){

    window.debugBookingCalendar = [];
    dateString = this.generateDate(dateString);

    this.Ymd = dateString[0];
    this.His = dateString[1];

    if( this.His.length == 2 ){

      return this.findDatesPerHour(true);
    }

    if( this.His.length == 1 ){

      return this.findDatesPerDay(true);
    }

    else if( this.Ymd.length == 3 ){

      return this.findDatesPerMonth();
    }

    else if( this.Ymd.length == 2 ){

      return this.findDatesPerYear();
    }

    else if( this.Ymd.length == 1 ){

      return this.findDatesPerDecade();
    }

    return false;

  },

  this.findDatesPerDecade = function(){

    if(this.Ymd[0] * 1 != new Date().getFullYear() && this.Ymd[0] * 1 != new Date().getFullYear() + 1 )
      return false;

      var error = true;

      for(var j=1; j<= 12; j++){

        if(!error)
          continue;

        this.Ymd[1] = j;

        error = !this.findDatesPerYear();

        if(!error){
          return !error;
        }
      }

      return !error;


  };

  this.findDatesPerYear = function(){


      var error = true;

      error = !this.findDatesPerDay(true);

      if(!error)
        return error;

      for(var j=1; j<= new Date(this.Ymd[0], this.Ymd[1], 0).getDate(); j++){

        if(!error)
          continue;

        this.Ymd[2] = j;

        error = !this.findDatesPerMonth();

        if(!error){
          return !error;
        }
      }

      return !error;


  };

  this.findDatesPerMonth = function(){

    var error = true;

    // error = !this.findDatesPerDay(true);

    if(!error)
      return error;

    for(var i=0; i<24; i++){

      if(!error)
        continue;

      this.His = [Vue.prototype.$getNullsBefore(''+i+''),'00'];
      error = !this.findDatesPerDay(true);

      if(!error){
        return !error;
      }

    }
    return !error;

  };

  this.findDatesPerDay = function(checkDay){

    var interval = Vue.prototype.$getNextDate(new Date(this.Ymd[0], (this.Ymd[1] - 1), this.Ymd[2]), atts);

    var error = true;

    for( var i = 0; i<60; i+=interval ){

      if(!error)
        continue;

      this.His[1] = Vue.prototype.$getNullsBefore(i)+"";

      error = !this.findDatesPerHour(checkDay);
      if(!error){
        return !error;
      }
    }

    return !error;

  };

  this.findDatesPerHour = function(checkDay){
    checkDay = false
    window.debugBookingCalendar = [];
    var approvedDate = this.checkApprovedDate({
      Y: this.Ymd[0],
      m: this.Ymd[1],
      d: this.Ymd[2],
      H: this.His[0],
      i: this.His[1],
    });

    if( approvedDate !== null )
      return approvedDate;
    var r = new datepickerHours(atts);
    r.setAppointmentEnd = this.setAppointmentEnd;
    r.generateDate = function(dateString) { this.generateDate(dateString) };
    r.Ymd = this.Ymd;
    r.His = this.His;
    if(r.His[0] === 'empty' || typeof r.His[0] !== 'string'){
      r.His[0] = '00';
    }
    if(checkDay == true){
      window.debugBookingCalendar.push({info: 'Tages-Quickcheck des Prüfdatums: ' + this.Ymd[2] + '.' + this.Ymd[1] + '.' + this.Ymd[0]})
      r.checkDay();
      if (r.error === true){
        return false
      }
    }
    window.debugBookingCalendar.push({info: 'Ausgiebiger Check des Prüfdatums: ' + this.Ymd[2] + '.' + this.Ymd[1] + '.' + this.Ymd[0] + ', ' + this.His[0] + ':' + this.His[1] + ' Uhr'})
    r.checkDate();
    var data = JSON.parse(JSON.stringify(window.debugBookingCalendar));
    this.setMaxApplicants(r.Ymd, r.His, r.maxApplicants);
    this.setstore(r.Ymd, r.His, r.error, data);
    window.debugBookingCalendar = data;
    return !r.error;

  };

  this.checkApprovedDate = function(date){
    if(typeof this.setAppointmentEnd !== "undefined" && this.setAppointmentEnd){
      return this.checkApprovedDateEnd(date);
    }
    var approvedDates = store.getters.approvedDates;
    var debugDates = store.getters.debugDates;

    if(typeof approvedDates[ date.Y+""+date.m+""+date.d+""+date.H+""+date.i ] == "undefined")
      return null;


    window.debugBookingCalendar = debugDates[ date.Y+""+date.m+""+date.d+""+date.H+""+date.i ];
    return approvedDates[ date.Y+""+date.m+""+date.d+""+date.H+""+date.i ];

  };

  this.checkApprovedDateEnd = function(date){
    var approvedDatesEnd = store.getters.approvedDatesEnd;
    var debugDates = store.getters.debugDates;

    if(typeof approvedDatesEnd[ date.Y+""+date.m+""+date.d+""+date.H+""+date.i ] == "undefined")
      return null;

    window.debugBookingCalendar = debugDates[ date.Y+""+date.m+""+date.d+""+date.H+""+date.i ];
    return approvedDatesEnd[ date.Y+""+date.m+""+date.d+""+date.H+""+date.i ];
  };

  this.setMaxApplicants = function(Ymd, His, maxApplicants) {

    if (typeof maxApplicants === 'undefined') {
      this.approvedApplicants = false
      return false
    }

    this.approvedApplicants = {
        Y: Ymd[0],
        m: Ymd[1],
        d: Ymd[2],
        H: His[0],
        i: His[1],
        state: maxApplicants
      }

  },
  this.setstore = function(Ymd, His, state, debug){

    store.commit("approvedDate",
      {
        Y: Ymd[0],
        m: Ymd[1],
        d: Ymd[2],
        H: His[0],
        i: His[1],
        state: !state
      }
    );

    store.commit("debugDate", {
      Y: Ymd[0],
      m: Ymd[1],
      d: Ymd[2],
      H: His[0],
      i: His[1],
      state: debug
    })
  };

  this.generateDate = function(dateString){

    dateString = dateString.toString();

    dateString = dateString.split(" ");

    var Ymd = dateString[0].split( "-" );
    var His = [];

    if(typeof dateString[1] == "string")
      His = dateString[1].split(":");

    return [Ymd, His];
  };




};

export default datePickerRules
