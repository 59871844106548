<template>
  <div>

    <serviceInfo />
      <div :key="getUpdateKey()">
        <div v-if="data && data.length" class="dates">
          <a
          class="active"
          @mouseenter="removeStyle"
          @mouseleave="setStyle"
          @click.prevent="getClick(date)"
          href="#"
          v-for="(date, index) in data"
          :key="index"
          :title="date + ' ' + oClock"
          :style="getStyling(date)">
            {{ $getNullsBefore(date) }} <span>{{ oClock }}</span>
          </a>
        </div>
        <bookingNotPossible :callback="function(){ $store.commit('setView', -1) }" v-else />
      </div>


    <changeTime />
    <debugTime />
  </div>
</template>

<script>

  import changeTime from '@/components/functions/changeTime';
  import debugTime from '@/components/functions/debugTime';
  import serviceInfo from '@/components/functions/serviceInfo';
  import bookingNotPossible from '@/components/bookingNotPossible';

  export default{

    props: {date: String, callback: Function, day: Date},
    components: {changeTime, debugTime, serviceInfo, bookingNotPossible},

    data(){
      var date = this.day

      return{
        rows: Object.keys([1, 2, 3, 4, 5, 6]),
        columns: Object.keys([1, 2, 3, 4]),
        oClock: this.$store.getters.l.oClock,
        weekdays: this.$store.getters.weekdays,
        currDay: this.$getNullsBefore()+"."+this.$getNullsBefore(date.getMonth() + 1)+"."+date.getFullYear(),
        interval: this.$getNextDate(date, this.$store.getters.data),
        data: false,
        updateKey: new Date().getTime()
      }
    },

    watch: {
      currDay: function () { this.data = false; this.getData() }
    },

    methods:{
      getUpdateKey () {
        return this.updateKey
      },
      getData () {
        this.$makeCalendar()

        var data = this.$store.getters.approvedDates
        if (typeof data === 'undefined' || typeof data[this.day.getFullYear()] === 'undefined' || typeof data[this.day.getFullYear()][this.day.getMonth() * 1 + 1] === 'undefined' || typeof data[this.day.getFullYear()][this.day.getMonth() * 1 + 1][this.day.getDate()] === 'undefined') {
          return false
        }

        var day =  data[this.day.getFullYear()][this.day.getMonth() * 1 + 1][this.day.getDate()]

        var ret = []
        Object.keys(day).forEach(function(hours){
          ret.push(hours)
        })

        if(ret.length){
          this.data = ret
        }
      },
      removeStyle (event) {
        var color = this.$getStyling(['color'], 'accent')
        if (event.target.classList.contains('active')) {
          if (color) {
            event.target.style['background-color'] = color.color
          }
        }
      },
      setStyle (event) {
        var color = this.$getStyling(['color'], 'accent')
        if (event.target.classList.contains('active')) {
          if (color) {
            event.target.style['background-color'] = 'inherit'
          }
        }
      },
      getStyling () {
        var style = this.$getStyling(['border-bottom-color'], 'accent')

        return style
      },
      setMinutes (date) {
        var weekday = date.getDay();
        weekday--;
        if(weekday < 0){
          weekday = 6;
        }

        if (typeof this.$store.getters.data.include.weekdays[weekday] === 'undefined' || typeof this.$store.getters.data.include.weekdays[weekday][0] === 'undefined'){
          return date
        }
        var start = this.$store.getters.data.include.weekdays[weekday][0].split(":")

        if((start[0] * 1) === (date.getHours() * 1) || (this.interval * 1) === 60 ){
          date.setMinutes(start[1] * 1)
        } else {
          date.setMinutes(0)
        }
        return date;
      },
      setView(){

        this.$store.commit("setService", false);

        if(typeof this.callback == "function")
          this.callback(false);
        else
          this.$store.commit('setView', -1);


      },
      getClick(date){

        this.$store.commit('setHour', new Date( this.day.getFullYear(), (this.day.getMonth()), this.day.getDate(), date , 0 ));
        this.$store.commit('setView', 1);

      },

      isActive(i,j){
        var r = false

        for(var a = 0; a < 60; a += this.interval){

          if(r === true){
            continue;
          }
          if (this.checkDate(a, (i * 1 + i * 1 + i * 1 + i * 1 + j * 1 ))) {
            r = true;
          }
        }
        return r;
      },

      checkDate(minutes, hours){

        var b = new Date( this.day.valueOf() );
        b.setHours(hours);
        b = this.setMinutes(b);

        if ((b.getMinutes() * 1) < 45) {
          b.setMinutes(b.getMinutes() * 1 + minutes);
        }

        var e = new Date( b.valueOf() );
        e = this.$getEndDate(e);

        if(b.getDate() != e.getDate()){
          return false;
        }

        var check_begin = this.$checkDate( b.getFullYear()+'-'+this.$getNullsBefore((b.getMonth()+1))+'-'+this.$getNullsBefore(b.getDate())+' '+this.$getNullsBefore(b.getHours())+':'+this.$getNullsBefore(b.getMinutes()) );
        var check_end = this.$checkDate( e.getFullYear()+'-'+this.$getNullsBefore((e.getMonth()+1))+'-'+this.$getNullsBefore(e.getDate())+' '+this.$getNullsBefore(e.getHours())+':'+this.$getNullsBefore(e.getMinutes()), true);

        if(check_begin === true && check_end === true){
          this.updateKey = new Date().getTime() + Math.random()
          return this.checkTimeBetween (b, e);
        } else {
          return false;
        }
      },
      checkTimeBetween (b, e) {
        if (typeof this.interval === 'undefined' || !this.interval) {
          this.interval = 15;
        }
        var r = true
        var begin = new Date(b.valueOf())
        for (var i = 0; i<=1000; i+=1) {

          begin.setMinutes( begin.getMinutes() + (this.interval / 2) )

          if(r == false || e.getTime() - begin.getTime() <= 0) {
            return r
          }

          if (!this.$checkDate( begin.getFullYear()+'-'+this.$getNullsBefore((begin.getMonth()+1))+'-'+this.$getNullsBefore(begin.getDate())+' '+this.$getNullsBefore(begin.getHours())+':'+this.$getNullsBefore(begin.getMinutes()) )) {
            r = false
          }
        }
        return r
      },
      getWeekday(){


        var weekday = this.day.getDay();

        if(weekday == 0)
          weekday = 7;

        var wd = this.weekdays[weekday -1];

        return wd[0]+wd[1]+".";
      },

    },
    mounted () {
      this.data = []
      this.getData()
    }


  }

</script>

  <style lang="sass" scoped>

    @import '@/style/variables.sass'
    @import '@/style/hours.sass'

  </style>
