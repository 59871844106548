<template>

  <div v-if="$store.getters.viewBox != 'list'">
    <div class="debugTime" >
      <a href="#" v-on:click.prevent="open = !open" v-bind:class="{clicked: open}" :title="$store.getters.l['check']"></a>
    </div>

    <transition name="getDates">
      <div v-if="open" class="timePicker">

        <div>
          <select v-model="date.day">
            <option v-for="i in new Array(32).keys()" v-bind:key="i" v-show="i" v-bind:value="i" v-html="$getNullsBefore(i)"/>
          </select>

          <select v-model="date.month">
            <option v-for="i in new Array(13).keys()" v-bind:key="i" v-show="i" v-bind:value="i" v-html="months[i]"/>
          </select>

          <select v-model="date.year">
            <option v-for="i in new Array(2).keys()" v-bind:key="i+'plus'" v-bind:value="new Date().getFullYear() + (i)" v-html="new Date().getFullYear() + (i)"/>
          </select>
          -
          <select v-model="date.hour">
            <option v-for="i in new Array(24).keys()" v-bind:key="i" v-bind:value="i" v-html="$getNullsBefore(i)"/>
          </select>
          :
          <select v-model="date.minutes">
            <option v-for="i in new Array(4).keys()" v-bind:key="i * 15"  v-bind:value="i * 15" v-html="$getNullsBefore(i * 15)"/>
          </select>
          <a href="#" v-on:click.prevent="checkDate()">{{ $store.getters.l.check }}</a>

          <transition name="getDates">
            <div v-if="debug" :key="updateKey">
              <div v-for="(info, index) in debug" :key="index">
                <h3 v-if="info.info" v-html="info.info"></h3>
                <div v-else >
                  <b :style="{color: getStyle(info)}" v-html="$store.getters.l[info.text]"></b> <span :style="{'background-color': getStyle(info), color: '#fff', padding: '3px', 'border-radius': '5px'}" v-html="$store.getters.l[info.status]"></span>
                </div>
              </div>
            </div>
          </transition>
        </div>

      </div>
    </transition>
  </div>
</template>

<script>

export default{

  data(){

    var months = this.$store.getters.l.monthsNames;
    var date = this.$generateDate(this.$store.getters.date)

    return{
      open: false,
      months: months,
      debug: false,
      check: false,
      updateKey: new Date().getTime(),
      date: {
        day: date.getDate(),
        month: date.getMonth() * 1  + 1,
        year: date.getFullYear(),
        hour: date.getHours(),
        minutes: date.getMinutes()
      }

    }
  },

  methods:{
    getStyle(info) {
      if (info.error) {
        return  '#E53935'
      }
      return '#7CB342'
    },
    checkDate(){
      var date = this.date.year+'-'+this.$getNullsBefore(this.date.month)+'-'+this.$getNullsBefore(this.date.day)+' '+this.$getNullsBefore(this.date.hour)+':'+this.$getNullsBefore(this.date.minutes)
      this.check = this.$checkDate( date );
      this.debug = window.debugBookingCalendar;

      if (this.check) {
        date = this.$getEndDate(new Date(this.date.year, (this.date.month - 1), this.date.day, this.date.hour, this.date.minutes))
        if (!date) {
          this.updateKey = new Date().getTime();
          return false
        }
        date = {
          day: date.getDate(),
          month: date.getMonth() * 1  + 1,
          year: date.getFullYear(),
          hour: date.getHours(),
          minutes: date.getMinutes()
        };
        date = date.year+'-'+this.$getNullsBefore(date.month)+'-'+this.$getNullsBefore(date.day)+' '+this.$getNullsBefore(date.hour)+':'+this.$getNullsBefore(date.minutes)
        this.check = this.$checkDate( date );
        this.debug = JSON.parse(JSON.stringify(window.debugBookingCalendar));
        if (!this.check) {
          this.debug.push({'info': this.$store.getters.l.debugProblemsEnd})
          this.updateKey = new Date().getTime();
          return false
        }
      }
    }
  }

}

</script>

<style lang="sass" scoped>

  @import '@/style/variables.sass'
  @import '@/style/menu.sass'
  @import '@/style/debugTime.sass'

</style>
