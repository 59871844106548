<template>
  <div v-if="show">
    <transition name="getDates">
      <div :key="$store.state.info.startClick">
        <div v-if="!$store.state.info.startClick" class="startInfo" :style="getStyling()">
          <h4 :style="getStylingH3()">{{ $store.getters.l.importantInfos }}</h4>
          <p v-html="$store.getters.data.info.text">
          </p>
          <a href="#" class="acceptBtn" @click.prevent="$store.commit('setStartInfoClick', true)" :style="getStylingButton()">{{ $store.getters.data.info.button }}</a>
        </div>
      </div>
    </transition>
    <div class="startInfo unclicked" v-if="$store.state.info.startClick">
      <a class="acceptBtn" href="#" @click.prevent="$store.commit('setStartInfoClick', false)" :style="getStylingButton()">{{ $store.getters.l.importantInfos }}</a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      show: false
    }
  },
  computed: {

  },
  mounted () {
    this.checkInfos()
  },
  methods: {
    checkInfos () {
      if (typeof this.$store.getters.data.info === 'object') {
        this.show = true
        if (!this.$store.getters.data.info.onStart) {
          this.$store.commit("setStartInfoClick", true)
        }
      } else {
        this.$store.commit("setStartInfoClick", true)
      }
    },
    getStylingH3 () {
      var style = this.$getStyling(['color'], 'accent')
      return style
    },
    getStyling () {
      var style = this.$getStyling(['border-color'], 'accent')
      return style
    },
    getStylingButton () {
      var style = this.$getStyling(['background-color'], 'accent')
      return style
    }
  }
}
</script>
<style lang="sass" scoped>
  @import '@/style/variables.sass'
  @import '@/style/startinfo.sass'
</style>
