<template>
  <div>
    <component :date="date" :is="getForm()" />
  </div>
</template>
<script>
export default {
  components: {
    businessForm () { return import('@/components/form/form') },
    privateForm () { return import('@/components/form/private') }
  },
  props: {
    date: String
  },
  data () {
    return {

    }
  },
  methods: {
    getForm () {
      if (this.$store.getters.data.form === 'privateEvent') {
        return 'privateForm'
      }
      return 'businessForm'
    }
  },
  mounted () {
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
