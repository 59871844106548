<template>

  <div v-if="day" :key="updateKey">
    <b class="menu" v-if="checkEvent()">{{ $store.state.currentEvent.value.name }}</b>
    <div class="menu single">
      <a href="#" v-on:click.prevent="getCalendar()" :title="$store.getters.l.backToCalendar" v-html="getDateString()"></a>
      <div class="appointmentAlert" :style="$getStyling(['color'], 'accent')" v-if="$store.getters.data.showAppointments">{{ $store.getters.l.availibleApplicants.split('{count}').join(getMaxApplicants()) }}</div>
      <a href="#" class="info" v-on:click.prevent="getCalendar()">{{ $store.getters.l.backToCalendar }}</a>
    </div>

    <div style="float:none; clear:both"/>

    <div>
      <privateLocation />
    </div>

    <serviceInfo />


    <div>

      <div v-for="(element, index) in formElements" :key="index" :style="{width: ((element.columnName/12) * 100)+'%', float:'left', padding:'5px', 'box-sizing':'border-box'}">
        <div class="formPart">

            <component :is="getFormPart(element.type)" v-if="getFormPart(element.type)" :maxApplicants="getMaxApplicants()" :data="element" :callback="function(data){ callback(data, element) }" />
            <div v-else v-html="element.type"></div>

        </div>
      </div>

      <div style="clear: both; float: none" />

      <submitBtn :key="form" :date="getDateString()"/>
    </div>

  </div>

</template>

<script>
import serviceInfo from '@/components/functions/serviceInfo';
import submitBtn from '@/components/form/parts/submitBtn';

export default{

  props: {date: String},
  components: {
    serviceInfo,
    submitBtn,
    privateLocation () { return import('@/components/form/private/location') }
  },

  data(){

    return{
      form: false,
      save: {},
      oClock: this.$store.getters.l.oClock,
      weekdays: this.$store.getters.weekdays,
      day: false,
      currDay: false,
      formElements: false,
      updateKey: 0,
      datepickerDate: false
    }
  },
  mounted () {

    var date = this.$generateDate(this.date)
    this.day = date
    this.formElements = this.getFormElements()
    this.currDay = this.$getNullsBefore(date.getDate())+"."+this.$getNullsBefore(date.getMonth() + 1)+"."+date.getFullYear()
    this.updateKey = new Date().getTime()
    if (this.$store.getters.data.type === 'datepicker') {
      this.getDatePickerDay()
    }
  },
  methods:{
    checkEvent () {
      if( typeof this.$store.state.currentEvent === 'undefined') {
        return false
      }
      if( typeof this.$store.state.currentEvent.value === 'undefined') {
        return false
      }
      if( typeof this.$store.state.currentEvent.value.name === 'undefined') {
        return false
      }
      if( this.$store.state.currentEvent.value.name.length <= 1) {
        return false
      }
      if( typeof this.$store.state.currentEvent.value.name === this.$store.getters.data.title) {
        return false
      }

      return true
    },
    getDatePickerDay () {
      var dates = this.$store.getters.approvedDates
      this.datepickerDate = dates[this.day.getFullYear() * 1][this.day.getMonth() * 1 + 1][this.day.getDate()][this.day.getHours() * 1][this.day.getMinutes() * 1]
    },
    getFormElements () {
      var r = {}
      var form = JSON.parse(JSON.stringify(this.$store.getters.data.form))

      var saveFormData = JSON.parse(JSON.stringify(this.$store.getters.formEntries))
      Object.keys(form).forEach(function (k) {
        r[k] = form[k]
        r[k].value = ''
        if (typeof saveFormData === 'object' && typeof saveFormData[form[k].label] !== 'undefined') {
          r[k].value = saveFormData[form[k].label]
        }
      })
      return r
    },

    getMaxApplicants(){

      if (this.$store.getters.data.type === 'datepicker') {
        return this.getMaxApplicantsDatepicker()
      } else {
        return this.getMaxApplicantsEventpicker()
      }
    },
    getMaxApplicantsEventpicker(){

      var maxApplicants = this.$store.getters.currentEvent.max_applicants

      return (maxApplicants);
    },
    getMaxApplicantsDatepicker(){

      return (this.datepickerDate.maxApplicants[1] - this.datepickerDate.maxApplicants[0]);
    },
    callback(data, element){

      var save = {};
      save[ element.label ] = data;
      this.$store.commit('saveForm', save);

      this.form = new Date().getTime();
    },

    getFormPart(type){


      if( Object.keys(this.$store.getters.getForm).indexOf(type) == -1 )
        return false;


      return this.$store.getters.getForm[type];

    },
    getFormElement(type){

      return this.$store.getters.getForm[type];

    },

    getCalendar(){
      var date = this.$generateDate(this.date);
      var dateInfo = this.$getDateInfo(date);

      if (dateInfo.weekday[2] <= 60) {
        date.setMinutes(0);
      }

      this.$store.commit('setHour', date);
      this.$store.commit('setView', -1)

    },

    getWeekday(){

      var weekday = this.day.getDay();
      weekday -= 1
      if(weekday < 0)
        weekday = 6;

      return this.weekdays[weekday];
    },

    getDateString(){

      var data = this.$store.getters.data;

      if(data.type == "datepicker")
        return this.getWeekday()[0]+this.getWeekday()[1]+'. '+this.currDay+' – '+this.getDatePickerDate()+' '+this.$store.getters.l.oClock
      if(data.type == "eventpicker")
        return this.$store.getters.currentEvent.time[0] + '<br/>' + this.$store.getters.currentEvent.time[1]
    },

    getDatePickerDate(){

      var r = "";
      r += this.$getNullsBefore(this.datepickerDate.begin.getHours())+":"+this.$getNullsBefore(this.datepickerDate.begin.getMinutes());

      if(this.datepickerDate.begin.getTime() == this.datepickerDate.end.getTime())
        return r;

      r += ' - ';
      r += this.$getNullsBefore(this.datepickerDate.end.getHours())+":"+this.$getNullsBefore(this.datepickerDate.end.getMinutes() );

      return r;

    }

  }

}

</script>

  <style lang="sass" scoped>

    @import '@/style/variables.sass'
    @import '@/style/menu.sass'

    .formPart
      margin-bottom: 12px
      color: $black
    .appointmentAlert
      font-family: $font
      color: $active
      text-align: center
    td > div

      padding-bottom: 20px

      > span
        font-size: 8pt
        display: block

  </style>
