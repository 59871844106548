<template>
  <div>

    <div class="menu single"  v-if="$store.getters.currentService">

      <a  href="#" @click.prevent="function(){ callback(false) }" :title="$store.getters.l.backToCalendar">{{ $store.getters.l.change }}</a>

      <a href="#" @click.prevent="function(){ callback(false) }" class="info">{{ $store.getters.l.backToCalendar }}</a>
    </div>


    <serviceInfo />

    <transition name="getDates" :key="offset">
    <table cellspacing="0" cellpadding="0" >


        <tbody v-for="(date, index) in checkedDates" :key="index">


          <tr><td class="date" colspan="4">
            <span>
              {{ getWeekday(index) }}, {{ $getNullsBefore($generateDate(index).getDate()) }}.{{ $getNullsBefore($generateDate(index).getMonth() + 1) }}.{{ $generateDate(index).getFullYear() }}
            </span>
          </td></tr>

          <tr v-for="i in Array(( Math.ceil(date.length / 4))).keys()" :key="'row'+i+1">

            <td v-for="j in Array(4).keys()" :key="i + i + i + i + j + 2">
              <a
              href="#"
              class="flix-active"
              @click.prevent="getClick(date[i + i + i + i + j])"
              :style="$getStyling(['border-bottom-color'], 'accent')"
              @mouseenter="getHover"
              @mouseleave="removeHover"
              v-bind:class="{
                active: true
              }"
              v-if="date[i + i + i + i + j]"
              >
              {{ $getNullsBefore(date[i + i + i + i + j].begin.getHours()) }}
                   <span>{{ $store.getters.l.oClock }}</span>
              </a>
            </td>
          </tr>

      </tbody>

    </table>
  </transition>

  </div>
</template>

<script>

  import serviceInfo from '@/components/functions/serviceInfo';

  export default{

    components: { serviceInfo },
    props: {date: String, callback: Function},

    data(){

      return {
        offset: 1,
        checkedDates: false
      };

    },
    mounted () {
      this.get_dates()
    },
    methods:{
      get_dates(){

        this.$makeCalendar()

        var data = this.$store.getters.approvedDates

        if (typeof data === 'undefined') {
          return false
        }

        var ret = {}
        var check = []
        Object.keys(data).forEach(function (year) {
          Object.keys(data[year]).forEach(function (month) {
            Object.keys(data[year][month]).forEach(function (day) {
              if (!this.checkDay(year, month, day)) {
                return true
              }
              ret[year + '-' + month + '-' + day] = []
              Object.keys(data[year][month][day]).forEach(function (hour) {
                Object.keys(data[year][month][day][hour]).forEach(function (minutes) {
                  if (check.indexOf(year + '-' + month + '-' + day + '-' + hour) === -1){
                    ret[year + '-' + month + '-' + day].push(data[year][month][day][hour][minutes])
                    check.push(year + '-' + month + '-' + day + '-' + hour)
                  }
                }.bind(this))
              }.bind(this))
            }.bind(this))
          }.bind(this))
        }.bind(this))

        this.checkedDates = ret
      },
      checkDay (year, month, day) {
        var d = new Date()
        d.setYear(year)
        d.setMonth(month * 1 - 1)
        d.setDate(day)

        var weekday = d.getDay()
        weekday = weekday - 1
        if (weekday < 0) {
          weekday = 6
        }
        if (typeof this.$store.getters.data.include.weekdays[weekday] === 'undefined') {
          return false
        }

        if (this.$store.getters.currentService.short.indexOf(this.$store.getters.data.include.weekdays[weekday][2]) === -1) {
          return false
        }

        return true
      },
      getHover (event) {
        var bg = this.$getStyling(['background-color'], 'accent')

        if (bg !== false && event.target.classList.contains('active')) {
          event.target.style['background-color'] = bg['background-color']
        }
      },
      removeHover (event) {
        var bg = this.$getStyling(['background-color'], 'accent')
        if (bg !== false && event.target.classList.contains('active')) {
          event.target.style['background-color'] = 'inherit'
        }
      },
      getClick(date){

        var day = date.begin

        this.$store.commit('setHour', day);
        this.$store.commit('setView', 1);

      },

      getStringToDate(index){

        index = index.split(" ");
        index = index[0].split("-");

        return new Date(index[0], index[1]-1, index[2]);
      },

      getWeekday(index){

        var weekday = this.getStringToDate(index);
        weekday = weekday.getDay();

        if(weekday == 0)
          weekday = 7;

        return this.$store.getters.l.weekdays[weekday -1];
      },

      is_active(date){


        var begin = new Date(date.valueOf());
        var end = this.$getEndDate(begin);
        // var interval = this.$getNextDate(date, this.$store.getters.data)

        for(var k = 0; k<1000; k++){

          var check = new Date(date.valueOf())
          check.setMinutes((( k * 15)))


          if(check.getTime() > end.getTime()){
            break;
          }

          if( this.$checkDate(check.getFullYear()+'-'+this.$getNullsBefore((check.getMonth()+1))+'-'+this.$getNullsBefore(check.getDate())+" "+this.$getNullsBefore(check.getHours())+":"+this.$getNullsBefore(check.getMinutes())) == false)
            return false

        }
        return true

      }

    },



  }

</script>

  <style lang="sass" scoped>

    @import '@/style/variables.sass'
    @import '@/style/year.sass'
    @import '@/style/menu.sass'

    td > div, td > a.flix-active
      color: $black
      > span
        font-size: 8pt
        display: block
      > i
        font-size: 9pt
        display: block
        color: $active
        font-variant: normal
        font-style: normal
      &:hover
        > i
          color: white

    a.back, .more
      color: $black
      font-size: 10pt
      font-family: $font

    .date, .more
      font-weight: bold
      font-size: 16pt
      padding-top: 20px
      padding-bottom: 10px
      color: $black

    .more
      margin-top: 10px
      display: block
      text-align: center

  .app td.date
    text-align: left
    span
      display: block
      padding: 30px 0 5px 0

  </style>
