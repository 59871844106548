<template>
  <svg
     xmlns:dc="http://purl.org/dc/elements/1.1/"
     xmlns:cc="http://creativecommons.org/ns#"
     xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
     xmlns:svg="http://www.w3.org/2000/svg"
     xmlns="http://www.w3.org/2000/svg"
     xml:space="preserve"
     style="enable-background:new 0 0 512 512;"
     viewBox="0 0 512 512"
     y="0px"
     x="0px"
     id="Layer_1"
     version="1.1">
     <defs id="defs9" />
     <path
        class="st0"
        d="m 384.3,456 c -2.7,0 -5.7,-1.1 -8.8,-3.2 L 263.2,375.7 c -3.6,-2.4 -10.8,-2.5 -14.4,0 l -112.3,77.1 c -6.5,4.5 -12.9,4 -16.3,-0.4 -2.3,-3 -2.7,-7.1 -1.2,-12.2 l 33.9,-120.3 c 1.3,-4.8 -0.6,-12.3 -4,-15.8 L 61.5,214.9 c -6.1,-6.2 -5,-11.9 -4.2,-14 0.8,-2.1 3.5,-7.2 12.2,-8 L 185.1,182 c 4.9,-0.5 11.1,-5 13,-9.5 L 244.5,65 c 3.5,-8.2 9.1,-9.1 11.4,-9.1 2.2,0 7.8,0.9 11.4,9.1 l 46.5,107.5 c 1.9,4.5 8.1,9 13,9.5 l 115.6,10.8 c 8.7,0.8 11.5,5.9 12.2,8 0.8,2.1 1.9,7.8 -4.2,14 L 363,304 c -3.5,3.5 -5.4,11 -4,15.8 l 34,120.4 c 1.4,5 1,9.1 -1.2,12.1 -1.8,2.4 -4.5,3.7 -7.5,3.7 z"
        id="full"
        :class="{'active': checkBg('full')}"
        style="clip-rule:evenodd;fill-rule:evenodd" />
      <path
        :class="{'active': checkBg('threequarter')}"
        id="threequater"
        d="M 255.90039,55.900391 C 253.60039,55.900391 248,56.8 244.5,65 l -46.40039,107.5 c -1.9,4.5 -8.1,9 -13,9.5 L 69.5,192.90039 c -8.7,0.8 -11.399219,5.9 -12.199219,8 -0.8,2.1 -1.900781,7.8 4.199219,14 l 87.40039,89.19922 c 3.4,3.5 5.3,11.00078 4,15.80078 L 119,440.19922 c -1.5,5.1 -1.10078,9.20117 1.19922,12.20117 3.4,4.4 9.80078,4.90039 16.30078,0.40039 l 112.30078,-77.10156 c 3.6,-2.5 10.79844,-2.4 14.39844,0 l 58.69726,40.29883 V 180.42578 c -3.51363,-1.80122 -6.8018,-4.86129 -8.0957,-7.92578 l -46.5,-107.5 c -3.6,-8.2 -9.20039,-9.099609 -11.40039,-9.099609 z"
        style="clip-rule:evenodd;fill-rule:evenodd" />
      <path
        :class="{'active': checkBg('half')}"
        id="half"
        d="M 255.03711,55.947266 C 252.40764,56.18488 247.62524,57.678006 244.5,65 l -46.40039,107.5 c -1.9,4.5 -8.1,9 -13,9.5 L 69.5,192.90039 c -8.7,0.8 -11.399219,5.9 -12.199219,8 -0.8,2.1 -1.900781,7.8 4.199219,14 l 87.40039,89.19922 c 3.4,3.5 5.3,11.00078 4,15.80078 L 119,440.19922 c -1.5,5.1 -1.10078,9.20117 1.19922,12.20117 3.4,4.4 9.80078,4.90039 16.30078,0.40039 l 112.30078,-77.10156 c 1.58586,-1.1013 3.86876,-1.6966 6.23633,-1.81445 z"
      style="clip-rule:evenodd;fill-rule:evenodd" />
     <path
        :class="{'active': checkBg('quarter')}"
        id="quarter"
        d="m 186.64367,181.23633 c -1.04794,0.39336 -2.08999,0.66284 -3.07812,0.76367 L 67.965938,192.90039 c -8.7,0.8 -11.399219,5.9 -12.199219,8 -0.8,2.1 -1.900781,7.8 4.199219,14 l 87.400392,89.19922 c 3.4,3.5 5.3,11.00078 4,15.80078 l -33.90039,120.29883 c -1.5,5.1 -1.10078,9.20117 1.19922,12.20117 3.4,4.4 9.80078,4.90039 16.30078,0.40039 l 51.67773,-35.48047 z"
        style="clip-rule:evenodd;fill-rule:evenodd" />
     <path
        class="st0"
        d="m 384.3,456 c -2.7,0 -5.7,-1.1 -8.8,-3.2 L 263.2,375.7 c -3.6,-2.4 -10.8,-2.5 -14.4,0 l -112.3,77.1 c -6.5,4.5 -12.9,4 -16.3,-0.4 -2.3,-3 -2.7,-7.1 -1.2,-12.2 l 33.9,-120.3 c 1.3,-4.8 -0.6,-12.3 -4,-15.8 L 61.5,214.9 c -6.1,-6.2 -5,-11.9 -4.2,-14 0.8,-2.1 3.5,-7.2 12.2,-8 L 185.1,182 c 4.9,-0.5 11.1,-5 13,-9.5 L 244.5,65 c 3.5,-8.2 9.1,-9.1 11.4,-9.1 2.2,0 7.8,0.9 11.4,9.1 l 46.5,107.5 c 1.9,4.5 8.1,9 13,9.5 l 115.6,10.8 c 8.7,0.8 11.5,5.9 12.2,8 0.8,2.1 1.9,7.8 -4.2,14 L 363,304 c -3.5,3.5 -5.4,11 -4,15.8 l 34,120.4 c 1.4,5 1,9.1 -1.2,12.1 -1.8,2.4 -4.5,3.7 -7.5,3.7 z M 256,360.4 c 5.5,0 10.7,1.5 14.7,4.2 l 108.1,74.2 -32.5,-115.4 c -2.6,-9.3 0.6,-21.9 7.3,-28.8 l 86.9,-88.7 -114.9,-10.8 c -9.6,-0.9 -20.2,-8.6 -24,-17.5 L 256,72.2 210.4,177.8 c -3.9,9 -14.2,16.5 -24,17.4 l -114.9,10.9 86.9,88.6 c 6.7,6.9 10,19.5 7.4,28.8 l -32.5,115.4 108.1,-74.2 c 3.9,-2.8 9.1,-4.3 14.6,-4.3 z"
        :class="{'active': checkBg('quarter')}"
        id="star-border"
        style="clip-rule:evenodd;fill-rule:evenodd" />

  </svg>
</template>
<script>
export default {
  components: {},
  props: {
    bg: {
      type: [Boolean, String],
      default () { return false }
    }
  },
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {
    checkBg (value) {
      if (this.bg === true) {
        return true
      }
      const check = ['quarter', 'half', 'threequarter', 'full']

      if (check.includes(this.bg) === false) {
        return false
      }
      if (check.indexOf(this.bg) < check.indexOf(value)) {
        return false
      }
      return true
    }
  }
}
</script>
<style lang="sass" scoped>
  #star-border
    fill: #bbb
  #star-back, #quarter, #half, #full, #threequater
    fill: none
  #star-back, #quarter, #half, #full, #threequater, #star-border
    &.active
      fill: gold
</style>
