var info = function () {
  this.store = false
  this.t = false
  this.click = false

  this.getText = function () {
  this.calc()
  }

  this.calc = function () {
    if (this.click === 0) {
      this.text = this.store.getters.l.openCalendar
      this.subtext = this.store.getters.data.title
      return false
    }
    if (this.click === 1) {
      switch(this.store.getters.viewBox) {
        case 'list':
          this.getList()
        break;
        case 'calendar':
          this.getCalendar()
        break;
        case 'events':
          this.getEvents()
        break;
      }
      return false
    }
  }
  this.getEvents = function () {
    switch(this.store.state.currentView) {
      case 0:
        this.text = this.store.getters.l.step.replace('{step}', 1).replace('{of}', 2)
        this.subtext = this.store.getters.data.title
      break;
      case 1:
        this.text = this.store.getters.l.step.replace('{step}', 2).replace('{of}', 2)
        this.subtext = this.store.getters.l.fillForm
      break;
    }
  }
  this.getCalendar = function () {

    switch(this.store.state.currentView) {
      case 0:
        this.text = this.store.getters.l.step.replace('{step}', 1).replace('{of}', 6)
        this.subtext = this.store.getters.l.selectYear
      break;
      case 1:
        this.text = this.store.getters.l.step.replace('{step}', 2).replace('{of}', 6)
        this.subtext = this.store.getters.l.selectMonth
      break;
      case 2:
        this.text = this.store.getters.l.step.replace('{step}', 3).replace('{of}', 6)
        this.subtext = this.store.getters.l.selectDay
      break;
      case 3:
        this.text = this.store.getters.l.step.replace('{step}', 4).replace('{of}', 6)
        this.subtext = this.store.getters.l.selectTime
      break;
      case 4:
        this.text = this.store.getters.l.step.replace('{step}', 5).replace('{of}', 6)
        this.subtext = this.store.getters.l.selectAppointment
      break;
      case 5:
        this.text = this.store.getters.l.step.replace('{step}', 6).replace('{of}', 6)
        this.subtext = this.store.getters.l.fillForm
      break;
    }
  }
  this.getList = function () {

    switch(this.store.state.currentView) {
      case 0:
        this.text = this.store.getters.l.step.replace('{step}', 1).replace('{of}', 4)
        this.subtext = this.store.getters.l.selectService
        if ( Object.keys(this.store.state.currentService).length ) {
          this.text = this.store.getters.l.step.replace('{step}', 2).replace('{of}', 4)
          this.subtext = this.store.getters.l.selectTime
        }
      break;
      case 1:
        this.text = this.store.getters.l.step.replace('{step}', 3).replace('{of}', 4)
        this.subtext = this.store.getters.l.selectAppointment
      break;
      case 2:
        this.text = this.store.getters.l.step.replace('{step}', 4).replace('{of}', 4)
        this.subtext = this.store.getters.l.fillForm
      break;
    }

  }
}

export { info }
