import checkEnd from './checks/end.js'
import checkWeekday from './checks/weekday.js'
import checkPause from './checks/pause.js'
import checkExcludeDates from './checks/exclude_dates.js'
import checkExcludeBookings from './checks/exclude_bookings.js'
import checkTotalStart from './checks/total_start.js'
import checkTotalEnd from './checks/total_end.js'
import checkPeriodRange from './checks/period_range.js'
import checkLastDayBooking from './checks/last_day_booking.js'
import checkLastMinuteBooking from './checks/last_minute_booking.js'

var datepickerHours = function(atts){

  this.atts = atts;
  this.error = false;

  this.checkDay = function(){

    this.date = new Date(this.Ymd[0],this.Ymd[1]-1,this.Ymd[2], 23, 59, 59, 59 );
    this.checkEnd();

    if( this.error )
      return this.error;

    this.checkWeekday({wholeDay: true});

    if( this.error )
      return this.error;

  };

  this.checkDate = function(){


    if( this.His[0] > 23 )
      return true;

    this.date = new Date(this.Ymd[0],this.Ymd[1]-1,this.Ymd[2], this.His[0], this.His[1], 0, 0 );

    this.checks();


    return this.error;


  };

  this.checkPause = function(){

    var check = new checkPause(JSON.parse(JSON.stringify(atts)));
    check.end = this.setAppointmentEnd;
    check.date = this.date;
    check.check();

    if(check.error)
      this.error = check.error;

  };

  this.checkExcludeDates = function(settings){

    var check = new checkExcludeDates(atts);
    check.settings = settings;
    check.date = this.date;
    check.check();

    if(check.error)
      this.error = check.error;

  };

  this.checkExcludeBookings = function(){

    var check = new checkExcludeBookings(atts);
    check.end = this.setAppointmentEnd;
    check.date = this.date;
    check.check();

    this.maxApplicants = check.maxApplicants

    if(check.error)
      this.error = check.error;

  };

  this.checkTotalStart = function(){

    var check = new checkTotalStart(atts);
    check.date = this.date;
    check.check();

    if(check.error)
      this.error = check.error;

  };

  this.checkTotalEnd = function(){

    var check = new checkTotalEnd(atts);
    check.date = this.date;
    check.check();

    if(check.error)
      this.error = check.error;

  };

  this.checkWeekday = function(settings){

    var check = new checkWeekday(atts);
    check.settings = settings;
    check.date = this.date;
    check.check();

    if(check.error)
      this.error = check.error;

  };

  this.checkPeriodRange = function(){

    var check = new checkPeriodRange(atts);
    check.date = this.date;
    check.check();

    if(check.error)
      this.error = check.error;

  };

  this.checkEnd = function(){

    var check = new checkEnd(atts);
    check.date = this.date;
    check.check();

    if(check.error)
      this.error = check.error;

  };

  this.checkLastDayBooking = function(){

    var check = new checkLastDayBooking(atts);
    check.date = this.date;
    check.check();

    if(check.error)
      this.error = check.error;

  };

  this.checkLastMinuteBooking = function(){

    var check = new checkLastMinuteBooking(atts);
    check.date = this.date;
    check.check();

    if(check.error)
      this.error = check.error;

  };

  this.checks = function(){

    this.checkEnd();

    if(this.error)
      return this.error;

    this.checkLastDayBooking();

    if(this.error)
      return this.error;

    this.checkLastMinuteBooking();

    if(this.error)
      return this.error;

    this.checkTotalStart();

    if(this.error)
      return this.error;

    this.checkTotalEnd();

      if(this.error)
        return this.error;

    this.checkPeriodRange();

      if(this.error)
        return this.error;

    this.checkWeekday({wholeDay: false});

      if(this.error)
        return this.error;

    this.checkPause();

    if(this.error)
      return this.error;

    this.checkExcludeDates();

      if(this.error)
        return this.error;

    this.checkExcludeBookings();

      if(this.error)
        return this.error;
  };

};

export default datepickerHours;
