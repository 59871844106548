<template>
  <a v-if="!iframe" href="#" class="fullscreen" @click.prevent="setFullScreen" :class="{close: $store.state.fullscreen}" :title="$store.getters.l.fullscreen">
    <svg v-if="!$store.state.fullscreen" width="25" height="25" xmlns="http://www.w3.org/2000/svg" xmlns:serif="http://www.serif.com/" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" clip-rule="evenodd" viewBox="0 0 512 512"><path d="M2215.87,2570C2240.91,2570.12 2265.83,2578.56 2285.7,2593.8C2313.49,2615.12 2330.72,2649.42 2331,2684.76C2331.35,2756.79 2331.1,2828.83 2331,2900.87C2330.88,2925.91 2322.44,2950.83 2307.2,2970.7C2285.88,2998.49 2251.58,3015.72 2216.24,3016C2144.21,3016.35 2072.17,3016.1 2000.13,3016C1974.64,3015.87 1949.3,3007.14 1929.2,2991.35C1901.91,2969.9 1885.16,2935.51 1885,2900.87C1884.87,2828.96 1884.87,2757.04 1885,2685.13C1885.22,2637.95 1916.75,2592.38 1962.47,2576.42C1974.42,2572.25 1987.07,2570.1 1999.76,2570C2071.79,2569.65 2143.83,2569.9 2215.87,2570ZM2000.51,2586C1969.62,2586.04 1939.05,2601.41 1920.6,2626.23C1908.06,2643.09 1901.1,2663.82 1901,2685.18C1900.87,2757.17 1900.67,2829.16 1901,2901.14C1901.26,2933.3 1918.06,2964.76 1944.64,2982.83C1960.81,2993.82 1980.14,2999.85 1999.86,3000C2071.95,3000.35 2144.05,3000.35 2216.14,3000C2247.96,2999.75 2279.03,2983.32 2297.15,2957.34C2308.56,2940.99 2314.84,2921.15 2315,2901.14C2315.35,2829.05 2315.35,2756.95 2315,2684.86C2314.76,2654.53 2299.91,2624.87 2275.96,2606.5C2258.95,2593.45 2237.65,2586.17 2216.14,2586C2144.27,2585.65 2072.39,2586 2000.51,2586ZM1999.63,2912.68L2086.93,2911.27L2087.19,2927.26L1971.87,2929.13L1973.74,2813.81C1979.07,2813.89 1984.4,2813.98 1989.73,2814.07L1988.32,2901.37L2057.34,2832.34C2061.11,2836.11 2064.89,2839.89 2068.66,2843.66L1999.63,2912.68ZM2216.37,2673.32L2129.07,2674.73L2128.81,2658.74L2244.13,2656.87C2243.51,2695.31 2242.89,2733.75 2242.26,2772.19L2226.27,2771.93L2227.68,2684.63L2158.66,2753.66L2147.34,2742.34L2216.37,2673.32Z" transform="translate(-1852 -2537)" serif:id="expanded ready"/></svg>
    <svg v-else
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   id="svg4"
   width="25" height="25"
   version="1.1"
   viewBox="0 0 512 512"
   clip-rule="evenodd"
   stroke-miterlimit="2"
   stroke-linejoin="round"
   fill-rule="evenodd">
  <path
     d="m 304.57,218.74 87.3,-1.41 0.26,15.99 -115.32,1.87 c 0.62,-38.44 1.24,-76.88 1.87,-115.32 l 15.99,0.26 -1.41,87.3 69.02,-69.03 11.32,11.32 z m -97.14,74.52 -87.3,1.41 -0.26,-15.99 115.32,-1.87 -1.87,115.32 c -5.33,-0.08 -10.66,-0.17 -15.99,-0.26 l 1.41,-87.3 -69.02,69.03 c -3.77,-3.77 -7.55,-7.55 -11.32,-11.32 z M 148.51,49 C 117.62,49.04 87.05,64.41 68.6,89.23 56.06,106.09 49.1,126.82 49,148.18 c -0.13,71.99 -0.33,143.98 0,215.96 0.26,32.16 17.06,63.62 43.64,81.69 16.17,10.99 35.5,17.02 55.22,17.17 72.09,0.35 144.19,0.35 216.28,0 31.82,-0.25 62.89,-16.68 81.01,-42.66 11.41,-16.35 17.69,-36.19 17.85,-56.2 0.35,-72.09 0.35,-144.19 0,-216.28 C 462.76,117.53 447.91,87.87 423.96,69.5 406.95,56.45 385.65,49.17 364.14,49 292.27,48.65 220.39,49 148.51,49 Z M 363.87,33 c 25.04,0.12 49.96,8.56 69.83,23.8 27.79,21.32 45.02,55.62 45.3,90.96 0.35,72.03 0.1,144.07 0,216.11 -0.12,25.04 -8.56,49.96 -23.8,69.83 -21.32,27.79 -55.62,45.02 -90.96,45.3 -72.03,0.35 -144.07,0.1 -216.11,0 C 122.64,478.87 97.3,470.14 77.2,454.35 49.91,432.9 33.16,398.51 33,363.87 32.87,291.96 32.87,220.04 33,148.13 33.22,100.95 64.75,55.38 110.47,39.42 c 11.95,-4.17 24.6,-6.32 37.29,-6.42 72.03,-0.35 144.07,-0.1 216.11,0 z"
     id="path2" />
</svg>
<span>{{ $store.getters.l.fullscreen }}</span>
  </a>
</template>
<script>
export default {
  data () {
    return {
      fullscreen: this.$store.state.fullscreen,
      iframe: this.inIframe()
    }
  },
  methods: {
    setFullScreen () {
      this.$store.commit('setFullScreen')
      this.fullscreen = this.$store.state.fullscreen
    },
    inIframe () {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
  }
}
</script>
<style lang="sass" scoped>
  @import '@/style/variables.sass'
  .fullscreen
    position: relative
    text-align: right
    width: 100%
    display: block
    color: $black
    font-size: 8pt
    font-family: $font
    text-decoration: none
    z-index: 10
    .close
      text-decoration: line-through
    span
      display: block
    svg
      fill: $black
</style>
