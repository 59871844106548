<template>
  <div>

    <div class="menu">
      <changeView :settings="{action: 'previous'}" :callback="function() { setDay(-1) }" />
      <a href="#" v-on:click.prevent="getBack()" :title="$store.getters.l.backToHourView" v-html="getWeekday()+' '+currDay+'<br />'+getTimeRange()+' '+oClock"></a>
      <changeView :callback="function() { setDay(1) }" />
      <a href="#" v-on:click.prevent="getBack()" class="info">{{ $store.getters.l.backToHourView }}</a>
    </div>

    <serviceInfo />

    <table cellspacing="0" cellpadding="0" v-if="checkedDates && Object.keys(checkedDates).length">

      <transition name="getDates">
        <tbody :key="currDay + day.getHours()">
        <tr v-for="(date, i) in checkedDates" :key="'row'+i+1" >
          <td>
            <a
            href="#"
            :title="getWeekday()+', '+currDay+', '+getDate(date)+' '+oClock"
            @click.prevent="getClick(date)"
            class="flix-active"
            @mouseenter="removeStyle" @mouseleave="setStyle"
            :style="$getStyling(['border-bottom-color'], 'accent')"
            :class="{
              active: true,
              current: ''

              }" >

              {{ getDate(date) }} <span>{{ oClock }}</span> <i v-if="$store.getters.data.showAppointments" :style="$getStyling(['color'], 'accent')">– {{ $store.getters.l.availibleApplicants.split('{count}').join(date.maxApplicants[1] - date.maxApplicants[0]) }} –</i>

            </a>
          </td>
        </tr>
      </tbody>
    </transition>

    </table>
    <bookingNotPossible :callback="function(){ $store.commit('setView', -1) }" v-else />
    <changeTime />
    <debugTime />
  </div>
</template>

<script>

  import changeTime from '@/components/functions/changeTime';
  import debugTime from '@/components/functions/debugTime';
  import serviceInfo from '@/components/functions/serviceInfo';
  import bookingNotPossible from '@/components/bookingNotPossible';
  import changeView from '@/components/functions/changeView';
  import checkLastDayBooking from '@/plugins/datepicker/checks/last_day_booking.js'

  export default{

    props: {date: String},
    components: {changeTime, debugTime, serviceInfo, bookingNotPossible, changeView},

    data(){
      var date = this.$generateDate(this.$store.getters.date)
      //date = this.setMinutes(date)

      return{
        oClock: this.$store.getters.oClock,
        weekdays: this.$store.getters.weekdays,
        day: date,
        currDay: this.$getNullsBefore(date.getDate())+"."+this.$getNullsBefore(date.getMonth() + 1)+"."+date.getFullYear(),
        checkedDates: false,
        interval: this.$getNextDate(date, this.$store.getters.data)
      }
    },
    mounted() {
      this.getCheckedDates()
    },
    methods:{
      removeStyle (event) {
        var color = this.$getStyling(['color'], 'accent')
        if (color) {
          event.target.style['background-color'] = color.color
          if (typeof event.target.getElementsByTagName('i') !== 'undefined' && typeof event.target.getElementsByTagName('i')[0] !== 'undefined' && typeof event.target.getElementsByTagName('i')[0].style !== 'undefined') {
            event.target.getElementsByTagName('i')[0].style.color = '#ffffff'
          }
        }
      },
      setStyle (event) {
        var color = this.$getStyling(['color'], 'accent')
        if (color) {
          event.target.style['background-color'] = 'inherit'
          if (typeof event.target.getElementsByTagName('i') !== 'undefined' && typeof event.target.getElementsByTagName('i')[0] !== 'undefined' && typeof event.target.getElementsByTagName('i')[0].style !== 'undefined') {
            event.target.getElementsByTagName('i')[0].style.color = color.color
          }
        }
      },
      setMinutes (date) {
        var interval = this.$getNextDate(date, this.$store.getters.data);
        var weekday = date.getDay();
        weekday--;
        if(weekday < 0){
          weekday = 6;
        }

        if (typeof this.$store.getters.data.include.weekdays[weekday] === 'undefined' || typeof this.$store.getters.data.include.weekdays[weekday][0] === 'undefined'){
          return date
        }
        var start = this.$store.getters.data.include.weekdays[weekday][0].split(":")
        if((start[0] * 1) === (date.getHours() * 1) || (interval * 1) === 60 ){
          date.setMinutes(start[1] * 1)
        } else {
          date.setMinutes(0)
        }
        return date;
      },
      getTimeRange () {
        var endHour = new Date(this.day.valueOf())
        endHour.setHours(endHour.getHours() + 1)

        return this.$getNullsBefore(this.day.getHours()) + ':xx '
      },
      getCheckedDates(){
        this.checkedDates = false

        this.$makeCalendar()

        var data = this.$store.getters.approvedDates

        if (typeof data === 'undefined' || typeof data[this.day.getFullYear()] === 'undefined' || typeof data[this.day.getFullYear()][this.day.getMonth() * 1 + 1] === 'undefined' || typeof data[this.day.getFullYear()][this.day.getMonth() * 1 + 1][this.day.getDate()] === 'undefined' || typeof data[this.day.getFullYear()][this.day.getMonth() * 1 + 1][this.day.getDate()][this.day.getHours()] === 'undefined') {
          return false
        }
        var hours = data[this.day.getFullYear()][this.day.getMonth() * 1 + 1][this.day.getDate()][this.day.getHours()]

        var ret = []
        Object.keys(hours).forEach(function (minutes){
          ret.push(hours[minutes])
        })

        if(ret.length){
          this.checkedDates = ret
        }
      },
      isActive(i){
        var begin = new Date(this.day.valueOf())
        begin.setMinutes(this.day.getMinutes() + (i * 1 * this.interval))

        if (begin.getHours() * 1 > this.day.getHours() * 1){
          return false;
        }
        var end = this.$getEndDate(begin);


        var check_end = new checkLastDayBooking(this.$store.getters.data)
        check_end.date = end
        check_end.check()

        if (check_end.error) {
          return false
        }

        if (this.interval < 60) {
          return this.checkDatesSmaller60Minutes(i, begin, end)
        } else {
          return this.$checkDate(begin.getFullYear()+'-'+this.$getNullsBefore((begin.getMonth()+1))+'-'+this.$getNullsBefore(begin.getDate())+" "+this.$getNullsBefore(begin.getHours())+":"+this.$getNullsBefore(begin.getMinutes()))
        }

      },

      checkDatesSmaller60Minutes(i, begin, end) {

        for(var j = 0; j<1000; j++){

          var check = new Date(this.day.valueOf())
          check.setMinutes(this.day.getMinutes() + (i * 1 * this.interval + ( j * 5)))

          if(check.getTime() > end.getTime()){
            break;
          }
          if( this.$checkDate(check.getFullYear()+'-'+this.$getNullsBefore((check.getMonth()+1))+'-'+this.$getNullsBefore(check.getDate())+" "+this.$getNullsBefore(check.getHours())+":"+this.$getNullsBefore(check.getMinutes())) == false) {
            return false
          }

        }

        return true
      },

      getBack(){

        this.$store.commit('setDay', new Date( this.day.getFullYear(), (this.day.getMonth()), this.day.getDate(),0, 0 ));
        this.$store.commit('setView', -1)

      },

      getClick(date){
        this.$store.commit('setHour', date.begin);
        this.$store.commit('setView', 1);

      },
      getDate(date){

        var newDay = date.begin
        var end = date.end;

        var r = "";
        r += this.$getNullsBefore(newDay.getHours())+":"+this.$getNullsBefore(newDay.getMinutes());

        if(end.getTime() == newDay.getTime())
          return r;

        r += ' - ';
        r += this.$getNullsBefore(end.getHours())+":"+this.$getNullsBefore(end.getMinutes() );

        return r;

      },

      getMaxApplicants(i){



        var newDay = new Date(this.day.getTime());
        newDay.setMinutes(this.day.getMinutes() * 1 + (i * this.interval) );

        var end = this.$getEndDate(newDay);
        end.setMinutes(end.getMinutes() - 5)
        var maxApplicants = this.$store.getters.approvedApplicants



        if (newDay.getTime() === end.getTime()) {
          maxApplicants = maxApplicants[newDay.getFullYear() + '' + this.$getNullsBefore(newDay.getMonth() + 1) + '' + this.$getNullsBefore(newDay.getDate()) + '' + this.$getNullsBefore(newDay.getHours()) + '' + this.$getNullsBefore(newDay.getMinutes())]
        } else {

          if (typeof this.$store.getters.approvedApplicants === 'undefined' || typeof maxApplicants[newDay.getFullYear() + '' + this.$getNullsBefore(newDay.getMonth() + 1) + '' +  this.$getNullsBefore(newDay.getDate()) + '' + this.$getNullsBefore(newDay.getHours()) + '' + this.$getNullsBefore(newDay.getMinutes())] === 'undefined') {
            return 0
          }

          var check = maxApplicants[newDay.getFullYear() + '' + this.$getNullsBefore(newDay.getMonth() + 1) + '' +  this.$getNullsBefore(newDay.getDate()) + '' + this.$getNullsBefore(newDay.getHours()) + '' + this.$getNullsBefore(newDay.getMinutes())][2]
          var ma = maxApplicants[newDay.getFullYear() + '' + this.$getNullsBefore(newDay.getMonth() + 1) + '' + this.$getNullsBefore(newDay.getDate()) + '' + this.$getNullsBefore(newDay.getHours()) + '' + this.$getNullsBefore(newDay.getMinutes())]

          maxApplicants = this.checkMaxApplicantsSmaller60Minutes(ma, maxApplicants, end, newDay, check)

        }

        return (maxApplicants[1] - maxApplicants[0])
        // return this.$store.getters.l.availibleApplicants.split('{count}').join((maxApplicants[1] - maxApplicants[0]));
      },

      checkMaxApplicantsSmaller60Minutes (ma, maxApplicants, end, newDay, check) {
        var nd = new Date(newDay.getTime())
          for (var j = 0; j <= ((end.getTime() - newDay.getTime()) / (60 * 1000) ); j+=5) {
          if(nd.getTime() > end.getTime()) {
            continue;
          }
          nd.setMinutes(nd.getMinutes() * 1 + j);
          var check2 = maxApplicants[nd.getFullYear() + '' + this.$getNullsBefore(nd.getMonth() + 1) + '' + this.$getNullsBefore(nd.getDate()) + '' + this.$getNullsBefore(nd.getHours()) + '' + this.$getNullsBefore(nd.getMinutes())]
          if (typeof check2 !== 'undefined' && check < check2[0]) {
            check = check2[0]
            ma = maxApplicants[nd.getFullYear() + '' + this.$getNullsBefore(nd.getMonth() + 1) + '' + this.$getNullsBefore(nd.getDate()) + '' + this.$getNullsBefore(nd.getHours()) + '' + this.$getNullsBefore(nd.getMinutes())]
          }

        }
        return ma
      },

      getWeekday(){

        var weekday = this.day.getDay();

        if(weekday == 0)
          weekday = 7;

        var wd = this.weekdays[(weekday -1)]
        return wd[0]+wd[1]+".";
      },
      setDay(add){

        var date = new Date(this.day);

        var days = this.$getDateInfo(this.day)

        if (typeof days.weekday[3] === 'number' && days.weekday[3] >= 60) {
          date.setMinutes(this.$generateDate(this.$store.getters.date).getMinutes() + (days.weekday[3] * add))
        }
        else {
          date.setHours( date.getHours() + add );
          date = this.setMinutes(date);
        }


        if (this.day.getDate() != date.getDate()) {
          this.$store.commit('setView', -1);
          return false;
        }
        this.day = date;

        this.currDay = this.$getNullsBefore(date.getDate())+"."+this.$getNullsBefore(date.getMonth() + 1)+"."+date.getFullYear()
        this.getCheckedDates()
      }
    }


  }

</script>

  <style lang="sass" scoped>

    @import '@/style/variables.sass'
    @import '@/style/year.sass'
    @import '@/style/menu.sass'
    .app
      td > div, td > a.flix-active
        color: $black
        > span
          font-size: 8pt
          display: block
        > i
          font-size: 9pt
          display: block
          color: $active
          font-variant: normal
          font-style: normal
        &:hover
          > i
            color: white

  </style>
