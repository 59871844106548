<template>

  <div v-if="timeSince(event.begin).indexOf('-') == -1">
    {{ $store.getters.l.inDate.replace("{date}", timeSince(event.begin)) }}:
  </div>

</template>

<script>

export default{

  props: {event: Object},

  methods: {

    timeSince(date) {

      var seconds = Math.floor((date - new Date()) / 1000);

      if(!seconds)
        return " "+this.$store.getters.l.lessSeconds;

      var interval = Math.floor(seconds / 31536000);

      if(interval == 1)
        return interval + " "+this.$store.getters.l.year;

      if (interval > 1)
        return interval + " "+this.$store.getters.l.years;

      interval = Math.floor(seconds / 2592000);

      if(interval == 1)
        return interval + " "+this.$store.getters.l.month;

      if (interval > 1)
        return interval + " "+this.$store.getters.l.months;

      interval = Math.floor(seconds / 86400);

      if(interval == 1)
        return interval + " "+this.$store.getters.l.day;

      if (interval >= 1)
        return interval + " "+this.$store.getters.l.days;

      interval = Math.floor(seconds / 3600);

      if(interval == 1)
        return interval + " "+this.$store.getters.l.hour;

      if (interval > 1)
        return interval + " "+this.$store.getters.l.hours;

      interval = Math.floor(seconds / 60);

      if (interval == 1)
        return interval + " "+this.$store.getters.l.minute;

      if (interval > 1)
        return interval + " "+this.$store.getters.l.minutes;

      if( Math.floor(seconds) == 1 )
        return seconds + " "+this.$store.getters.l.second;

      return Math.floor(seconds) + " "+this.$store.getters.l.seconds;
    },

  }
}

</script>
<style lang="sass" scoped>

div
  font-family: Courier
  font-size: 7pt
  font-weight: normal
  display: block
  text-align: center
  margin-bottom: 5px

</style>
