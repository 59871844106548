<template>
  <div>
    <div class="menu single">
      <a href="#" style="text-decoration: none; cursor: default;" v-on:click.prevent="" v-html="getDate()"></a>
    </div>
    <div>
      <privateLocation />
    </div>
    <div v-if="!password">
      <privatePassword :callback="function() { setPassword() }"/>
    </div>
    <div v-if="password">
      <privateInput v-if="!$store.getters.isBooked"/>
      <privateSuccess v-else/>
      <privateList :key="$store.getters.isBooked"/>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    privateLocation () { return import('@/components/form/private/location') },
    privatePassword () { return import('@/components/form/private/password') },
    privateInput () { return import('@/components/form/private/input') },
    privateSuccess () { return import('@/components/form/private/success') },
    privateList () { return import('@/components/form/private/list') }
  },
  props: {
    date: String
  },
  data () {
    return {
      password: false
    }
  },
  methods: {
    setPassword () {
      this.password = true
    },
    getWeekday (date) {
      var weekdays = this.$store.getters.l.weekdays
      var weekday = date.getDay()

      weekday = weekday -1
      if (weekday === 0) {
        weekday = 6
      }
      return weekdays[weekday][0] + weekdays[weekday][1] + "."
    },
    getDate () {

      var b = this.$generateDate(this.$store.getters.data.events[0].begin)
      var e = this.$generateDate(this.$store.getters.data.events[0].end)

      var r = ''

      if (b.getTime() === e.getTime()) {
        return this.getWeekday(b) + " " + this.$getNullsBefore(b.getDate()) + "." + this.$getNullsBefore((b.getMonth() + 1)) + "." + e.getFullYear() + ", " + this.$getNullsBefore(b.getHours()) + ":" + this.$getNullsBefore(b.getMinutes()) + " " + this.$store.getters.l.oClock
      }

      if (b.getFullYear() !== e.getFullYear()) {
        r += this.getWeekday(b) + " " + this.$getNullsBefore(b.getDate()) + "." + this.$getNullsBefore((b.getMonth() + 1)) + "." + b.getFullYear() + ", " + this.$getNullsBefore(b.getHours()) + ":" + this.$getNullsBefore(b.getMinutes()) + " " + this.$store.getters.l.oClock
        r += " - "
        r += this.getWeekday(e) + " " + this.$getNullsBefore(e.getDate()) + "." + this.$getNullsBefore((e.getMonth() + 1)) + "." + e.getFullYear() + ", " + this.$getNullsBefore(e.getHours()) + ":" + this.$getNullsBefore(e.getMinutes()) + " " + this.$store.getters.l.oClock
        return r
      }

      if (b.getMonth() !== e.getMonth()) {
        r += this.getWeekday(b) + " " + this.$getNullsBefore(b.getDate()) + "." + this.$getNullsBefore((b.getMonth() + 1)) + ", " + this.$getNullsBefore(b.getHours()) + ":" + this.$getNullsBefore(b.getMinutes()) + " " + this.$store.getters.l.oClock
        r += " - "
        r += this.getWeekday(e) + " " + this.$getNullsBefore(e.getDate()) + "." + this.$getNullsBefore((e.getMonth() + 1)) + "." + e.getFullYear() + ", " + this.$getNullsBefore(e.getHours()) + ":" + this.$getNullsBefore(e.getMinutes()) + " " + this.$store.getters.l.oClock
        return r
      }

      if (b.getDate() !== e.getDate()) {
        r += this.getWeekday(b) + " " + this.$getNullsBefore(b.getDate()) + "." + this.$getNullsBefore((b.getMonth() + 1)) + ", " + this.$getNullsBefore(b.getHours()) + ":" + this.$getNullsBefore(b.getMinutes()) + " " + this.$store.getters.l.oClock
        r += " - "
        r += this.getWeekday(e) + " " + this.$getNullsBefore(e.getDate()) + "." + this.$getNullsBefore((e.getMonth() + 1)) + "." + e.getFullYear() + ", " + this.$getNullsBefore(e.getHours()) + ":" + this.$getNullsBefore(e.getMinutes()) + " " + this.$store.getters.l.oClock
        return r
      }


      r += this.getWeekday(b) + " " + this.$getNullsBefore(b.getDate()) + "." + this.$getNullsBefore((b.getMonth() + 1)) + "." + b.getFullYear() + ", " + this.$getNullsBefore(b.getHours()) +  ":" + this.$getNullsBefore(b.getMinutes())
      r += " - "
      r += this.$getNullsBefore(e.getHours()) + ":" + this.$getNullsBefore(e.getMinutes()) + " " + this.$store.getters.l.oClock
      return r

    }
  },
  mounted () {
  },
  computed: {

  }
}
</script>
  <style lang="sass" scoped>

    @import '@/style/variables.sass'
    @import '@/style/menu.sass'

    .formPart
      margin-bottom: 12px

    td > div

      padding-bottom: 20px

      > span
        font-size: 8pt
        display: block
  </style>
