<template>
  <div>
    <div class="formPart">
      <formElement :data="data" :callback="function(val) { checkPassword(val) }"/>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    formElement () { return import('@/components/form/parts/text') }
  },
  props: {
    callback: Function,

  },
  data () {
    return {
      password: this.$store.getters.data.password,
      data: {
        label: this.$store.getters.l.password,
        description: '',
        type: 'password'
      }
    }
  },
  methods: {
    checkPassword (val) {
      if (val === this.password) {
        this.callback()
      }
    }
  },
  mounted () {
    if (!this.password) {
      this.callback()
    }
  }
}
</script>
<style lang="sass" scoped>
  .formPart
    margin-left: 25%
    width: 50%
</style>
