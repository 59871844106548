<template>

  <div v-if="$store.getters.viewBox != 'list'">
    <div class="changeTime" >
      <a href="#" v-on:click.prevent="setOpen()" v-bind:class="{clicked: open}">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 489.2 489.2" style="enable-background:new 0 0 489.2 489.2;" xml:space="preserve">
		<path d="M177.8,238.1c0,4.5-3.6,8.1-8.1,8.1h-30.4c-4.5,0-8.1-3.6-8.1-8.1v-30.4c0-4.5,3.6-8.1,8.1-8.1h30.4
			c4.5,0,8.1,3.6,8.1,8.1V238.1z M241.3,207.8c0-4.5-3.6-8.1-8.1-8.1h-30.4c-4.5,0-8.1,3.6-8.1,8.1v30.4c0,4.5,3.6,8.1,8.1,8.1h30.4
			c4.5,0,8.1-3.6,8.1-8.1V207.8z M304.8,207.8c0-4.5-3.6-8.1-8.1-8.1h-30.4c-4.5,0-8.1,3.6-8.1,8.1v30.4c0,4.5,3.6,8.1,8.1,8.1h30.4
			c4.5,0,8.1-3.6,8.1-8.1V207.8z M177.8,269.6c0-4.5-3.6-8.1-8.1-8.1h-30.4c-4.5,0-8.1,3.6-8.1,8.1V300c0,4.5,3.6,8.1,8.1,8.1h30.4
			c4.5,0,8.1-3.6,8.1-8.1V269.6z M241.3,269.6c0-4.5-3.6-8.1-8.1-8.1h-30.4c-4.5,0-8.1,3.6-8.1,8.1V300c0,4.5,3.6,8.1,8.1,8.1h30.4
			c4.5,0,8.1-3.6,8.1-8.1V269.6z M296.7,261.5h-30.4c-4.5,0-8.1,3.6-8.1,8.1V300c0,4.5,3.6,8.1,8.1,8.1h30.4c4.5,0,8.1-3.6,8.1-8.1
			v-30.4C304.8,265.1,301.2,261.5,296.7,261.5z M106.1,323.3H75.8c-4.5,0-8.1,3.6-8.1,8.1v30.4c0,4.5,3.6,8.1,8.1,8.1h30.4
			c4.5,0,8.1-3.6,8.1-8.1v-30.4C114.3,326.9,110.6,323.3,106.1,323.3z M114.3,269.6c0-4.5-3.6-8.1-8.1-8.1H75.8
			c-4.5,0-8.1,3.6-8.1,8.1V300c0,4.5,3.6,8.1,8.1,8.1h30.4c4.5,0,8.1-3.6,8.1-8.1V269.6z M233.2,323.3h-30.4c-4.5,0-8.1,3.6-8.1,8.1
			v30.4c0,4.5,3.6,8.1,8.1,8.1h30.4c4.5,0,8.1-3.6,8.1-8.1v-30.4C241.3,326.9,237.7,323.3,233.2,323.3z M169.7,323.3h-30.4
			c-4.5,0-8.1,3.6-8.1,8.1v30.4c0,4.5,3.6,8.1,8.1,8.1h30.4c4.5,0,8.1-3.6,8.1-8.1v-30.4C177.8,326.9,174.2,323.3,169.7,323.3z
			M360.2,246.3c4.5,0,8.1-3.6,8.1-8.1v-30.4c0-4.5-3.6-8.1-8.1-8.1h-30.4c-4.5,0-8.1,3.6-8.1,8.1v30.4c0,4.5,3.6,8.1,8.1,8.1H360.2
			z M47.7,435.9h230.7c-3.7-11.6-5.8-24-5.9-36.8H47.7c-6,0-10.8-4.9-10.8-10.8V171h361.7v101.1c12.8,0.1,25.2,2,36.8,5.7V94.9
			c0-26.3-21.4-47.7-47.7-47.7h-53.4V17.8c0-9.6-7.8-17.4-17.4-17.4h-27.1c-9.6,0-17.4,7.8-17.4,17.4v29.5H163V17.8
			c0-9.6-7.8-17.4-17.4-17.4h-27.1c-9.6,0-17.4,7.8-17.4,17.4v29.5H47.7C21.4,47.3,0,68.7,0,95v293.3C0,414.5,21.4,435.9,47.7,435.9
			z M489.2,397.7c0,50.3-40.8,91.1-91.1,91.1S307,448,307,397.7s40.8-91.1,91.1-91.1S489.2,347.4,489.2,397.7z M444.1,374.1
			c0-2.9-1.1-5.7-3.2-7.7c-4.3-4.3-11.2-4.3-15.5,0L385.8,406l-15.2-15.2c-4.3-4.3-11.2-4.3-15.5,0c-2.1,2.1-3.2,4.8-3.2,7.7
			c0,2.9,1.1,5.7,3.2,7.7l22.9,22.9c4.3,4.3,11.2,4.3,15.5,0l47.3-47.3C443,379.8,444.1,377,444.1,374.1z"/>

</svg>

  <span>{{ $store.getters.l.findDate }}</span>
      </a>
    </div>

    <transition name="getDates">
      <div v-if="open" class="timePicker">

        <div v-if="notToday()">
          <a href="#" :style="$getStyling(['color'], 'accent')" v-on:click.prevent="changeToday()">{{ $store.getters.l.today }}</a>
        </div>
        <select v-model="date.day" v-if="days.length" :style="$getStyling(['border-color'], 'accent')">
          <option v-for="i in days" v-bind:key="i[0]"  v-bind:value="(i[0])" v-html="i[1]"/>
        </select>

        <select v-model="date.month" :style="$getStyling(['border-color'], 'accent')">
          <option v-for="i in months" v-bind:key="i" v-show="i" v-bind:value="i" v-html="monthNames[i]"/>
        </select>

        <select v-model="date.year" :style="$getStyling(['border-color'], 'accent')">
          <option v-for="i in year" v-bind:key="i+'plus'" v-bind:value="i" v-html="i"/>
        </select>

        <a v-if="date.day && date.month && date.year" href="#" :style="$getStyling(['color'], 'accent')" v-on:click.prevent="changeDate()">{{ $store.getters.l.change }}</a>

      </div>
    </transition>
  </div>
</template>

<script>

export default{

  data(){

    var months = this.$store.getters.l.monthsNames;

    return{
      open: false,
      monthNames: months,

      date: this.$store.getters.searchDate,
      days: [],
      months: [],
      year: []
    }
  },

  mounted () {
  },
  watch: {
    'date.month' () { this.date.day = 1; this.getWeekday(); },
    'date.year' () { this.getMonths(); this.getWeekday(); }
  },
  methods:{
    setOpen () {
      this.open = !this.open

      if (this.open) {
        this.getYear();
        this.getWeekday();
        this.getMonths();
      }
    },


    getWeekday () {

      this.days = []
      var data = this.$store.getters.approvedDates


      var day = new Date()
      day.setYear(this.date.year)
      day.setMonth(this.date.month - 1)

      if (typeof data[this.date.year] === 'undefined') {
        this.date.year = Object.keys(data)[0]
      }
      if (typeof data[this.date.year][this.date.month] === 'undefined') {
        this.date.month = Object.keys(data[this.date.year])[0]
        this.date.day = Object.keys(data[this.date.year][this.date.month])[0]
      }
      if (typeof data[this.date.year][this.date.month][this.date.day] === 'undefined') {
        this.date.day = Object.keys(data[this.date.year][this.date.month])[0]
      }

      Object.keys(data[this.date.year][this.date.month]).forEach(function (date){
        var newDay = new Date(day)
        newDay.setDate(date)
        this.days.push([newDay.getDate(), this.$store.getters.l.weekdays[this.$getWeekday(newDay)] + ', ' + this.$getNullsBefore(newDay.getDate()) + '.'])
      }.bind(this))


    },

    getYear () {
      var data = this.$store.getters.approvedDates
      this.year = Object.keys(data)

    },

    getMonths () {

      var data = this.$store.getters.approvedDates
      var months = []

      Object.keys(data[this.date.year]).forEach(function (month){
        if (months.indexOf(month) === -1){
          months.push(month)
        }
      })


      this.months = months
      this.months.sort()
    },
    notToday(){

      var date = this.$store.getters.date;
      date = date.split(" ");
      date = date[0].split("-");

      var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0,0,0,0,0)

      var data = this.$store.getters.approvedDates

      if (typeof data[today.getFullYear()] === 'undefined' || typeof data[today.getFullYear()][today.getMonth() * 1 + 1] === 'undefined' || typeof data[today.getFullYear()][today.getMonth() * 1 + 1][today.getDate()] === 'undefined'){
        return false
      }

      if( new Date(date[0], date[1]-1, date[2], 0,0,0,0,0).getTime() === today.getTime() )
        return false;

      return true;
    },

    changeToday(){

      this.$store.commit("changeDate", new Date());

    },

    changeDate(){

      this.$store.commit("changeDate", new Date(this.date.year, this.date.month-1, this.date.day, 0,0,0,0,0));

    }
  }

}

</script>

<style lang="sass" scoped>

  @import '@/style/variables.sass'
  @import '@/style/menu.sass'
  @import '@/style/changeTime.sass'
</style>
