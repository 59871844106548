<template>
  <div>
    <div v-if="!$store.getters.data.type">
      <h1>{{ $store.getters.l.maintance }}</h1>
    </div>
    <div :key="$store.getters.save.length" v-else ref="app">
      <getFullScreen />
      <success v-if="$store.getters.save" :newBooking = "function () { newBooking() }"/>
      <div v-else-if="$store.getters.data.type">
        <calendarSwitch :callback="getReload"/>
        <component v-bind:is="$store.getters.data.type"   />
      </div>
      <span>
        <a class="bookingbrand" href="https://bookingflix.com" target="_blank" :title="$store.getters.l.brand + ' - ' + $store.getters.l.openCalendar" v-html="$store.getters.l.brand" style="text-decoration: none" :style="$getStyling(['color'], 'accent')"></a>
      </span>
    </div>
  </div>

</template>

<script>

import datepicker from '@/components/startBtn'
import eventpicker from '@/components/eventpicker'
import success from '@/components/success'
import calendarSwitch from '@/components/calendarSwitch'
import getFullScreen from '@/components/functions/fullscreen'

export default {
  name: 'app',
  props: {
    getReload: Function
  },
  components: { datepicker, eventpicker, success, calendarSwitch, getFullScreen },
  mounted() {
    if (typeof this.$store.getters.data === 'object' && typeof this.$store.getters.data.lang === 'string'){
      this.$store.commit('setLanguage', this.$store.getters.data.lang);
    }
    this.$cleanDates()
  },
  methods: {
    newBooking () {
      this.removeSavedEntry()
      this.getReload()
    },
    removeSavedEntry () {
      var data = this.$store.getters.approvedDates

      var save = this.$store.getters.save[2];
      save = new Date(save.begin.Y, save.begin.m - 1, save.begin.d, save.begin.H, save.begin.i, save.begin.s);

      if (typeof data === 'undefined') {
        return false
      }
      if (typeof data[save.getFullYear()] === 'undefined') {
        return false
      }
      if (typeof data[save.getFullYear()][save.getMonth() * 1 + 1] === 'undefined'){
        return false
      }
      if (typeof data[save.getFullYear()][save.getMonth() * 1 + 1][save.getDate()] === 'undefined'){
        return false
      }
      if (typeof data[save.getFullYear()][save.getMonth() * 1 + 1][save.getDate()][save.getHours()] === 'undefined'){
        return false
      }
      if (typeof data[save.getFullYear()][save.getMonth() * 1 + 1][save.getDate()][save.getHours()][save.getMinutes()] === 'undefined'){
        return false
      }

      var change = data[save.getFullYear()][save.getMonth() * 1 + 1][save.getDate()][save.getHours()][save.getMinutes()]
      if (typeof this.$store.getters.save[2].group === 'undefined' || !this.$store.getters.save[2].group) {
        change.maxApplicants[0] = change.maxApplicants[0] + 1
      } else {
        change.maxApplicants[0] = change.maxApplicants[0] + (this.$store.getters.save[2].group * 1)
      }
      change.maxApplicants[2] = Math.floor( (change.maxApplicants[0] / change.maxApplicants[1]) * 100 )
      
      if (change.maxApplicants[0] >= change.maxApplicants[1]) {
        delete data[save.getFullYear()][save.getMonth() * 1 + 1][save.getDate()][save.getHours()][save.getMinutes()]
      } else {
        data[save.getFullYear()][save.getMonth() * 1 + 1][save.getDate()][save.getHours()][save.getMinutes()] = change
      }

      this.$store.commit("setSettings", {approvedDates: data})

    }
  }

}
</script>

<style lang="sass" scoped>

  @import '@/style/variables.sass'
  h1
    font-family: $font
    font-size: 14pt
  .bookingbrand
    color: $active
  span
    font-size: 7pt
    color: $active
    display: block
    text-align: right
    margin-top: 8px
    font-family: $font

</style>
