<template>
  <div>

    <div class="menu">
      <changeView :settings="{action: 'previous'}" :callback="function() { setYear(-1) }" />
      <a href="#" v-html="currYear" :title="$store.getters.l.backToYearView" v-on:click.prevent="$store.commit('setView', -1)"></a>
      <changeView :callback="function() { setYear(1) }" />
      <a href="#" v-on:click.prevent="$store.commit('setView', -1)" class="info">{{ $store.getters.l.backToYearView }}</a>
    </div>
    <transition name="getDates">
      <div class="dates" :key="currMonth+''+currYear" v-if="data">
        <a v-for="(date, index) in Object.keys(data)" :key="index"
        href="#"
        @mouseenter="getHover"
        @mouseleave="removeHover"
        class="active"
        v-on:click.prevent="setClick(date)"
        :title="$store.getters.l.monthsNames[date] + ' ' + currYear"
        :class="{
          current: false
        }"
        :style="getStyling()"
        >{{ $store.getters.l.monthsNames[date] }}</a>
      </div>
      <bookingNotPossible :callback="function(){ $store.commit('setView', -1) }" v-else />
    </transition>

    <changeTime />
    <debugTime />
  </div>
</template>

<script>

  import changeTime from '@/components/functions/changeTime';
  import debugTime from '@/components/functions/debugTime';
  import bookingNotPossible from '@/components/bookingNotPossible';
  import changeView from '@/components/functions/changeView';
  import calculateView from '@/plugins/makeCalendar/calculateView'

  export default{

    props: {date: String},
    components:{changeTime, debugTime, bookingNotPossible, changeView},

    data(){

      var date = this.date.split("-");

      return{
        rows: Object.keys([1, 2, 3, 4]),
        columns: Object.keys([1, 2, 3]),
        currMonth: date[1] * 1,
        currYear: date[0] * 1,
        months: this.$store.getters.l.monthsNames,
        data: false,
        active_count: 0
      }
    },
    watch: {
      currYear () { this.data = false; this.getData() }
    },
    methods:{
      getData () {
        this.$makeCalendar()

        var data = this.$store.getters.approvedDates
        if (typeof data === 'undefined' || typeof data[this.currYear] === 'undefined') {
          return false
        }
        this.data = data[this.currYear]
      },
      getStyling () {
        //return {'border-color': 'red'}
        return this.$getStyling(['border-bottom-color'], 'accent')
      },
      getHover (event) {
        var bg = this.$getStyling(['background-color'], 'accent')

        if (bg !== false && event.target.classList.contains('active')) {
          event.target.style['background-color'] = bg['background-color']
        }
      },
      removeHover (event) {
        var bg = this.$getStyling(['background-color'], 'accent')
        if (bg !== false && event.target.classList.contains('active')) {
          event.target.style['background-color'] = 'inherit'
        }
      },
      setClick(date){

        this.$store.commit('setMonth', this.currYear+'-'+date);
        this.$store.commit('setView', 1)

      },
      isActive(i,j){

        return this.$checkDate(this.currYear+'-'+this.$getNullsBefore((i + i + i + j + 1)));

      },

      setYear(add){
        this.currYear = (this.currYear * 1) + (add * 1);
        this.$store.commit("setYear", this.currYear);
      },
      checkService () {
        var cV = new calculateView(this.$store)
        cV.data = this.$store.getters.data
        cV.run()
        cV.setView()
      }
    },
    mounted () {
      this.checkService()
      this.getData()
    }


  }

</script>

  <style lang="sass" scoped>

    @import '@/style/variables.sass'
    @import '@/style/year.sass'
    @import '@/style/menu.sass'


  </style>
