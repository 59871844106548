import { render, staticRenderFns } from "./text.vue?vue&type=template&id=0ad7de46&scoped=true&"
import script from "./text.vue?vue&type=script&lang=js&"
export * from "./text.vue?vue&type=script&lang=js&"
import style0 from "./text.vue?vue&type=style&index=0&id=0ad7de46&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ad7de46",
  null
  
)

export default component.exports