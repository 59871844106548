<template>

  <div>

    <div class="menu single" v-if="$store.getters.viewBox != 'list'">

      <a href="#" v-on:click.prevent="$store.commit('setView', -1)" :title="$store.getters.l.backToDayView" v-html="$store.getters.l.weekdays[getWeekday()]+', '+currDay"></a>
      <a href="#" v-on:click.prevent="$store.commit('setView', -1)" class="info">{{ $store.getters.l.backToDayView }}</a>
    </div>

    <table cellspacing="0" cellpadding="0">

      <transition name="getDates">
        <tbody>
        <tr v-for="(service, index) in getServices" v-bind:key="index">
          <td>
            <a
            href="#"
            v-if="service === 'default'"
            class="flix-active active"
            @click.prevent="openCalendar(false)"
            :style="$getStyling(['border-bottom-color'], 'accent')"
            @mouseenter="getHover"
            @mouseleave="removeHover"
            >
            <span>{{ $store.getters.l.bookingDate }}</span>
              {{ $store.getters.l.openCalendar }}
            </a>
            <a
            href="#"
            v-else
            class="flix-active active"
            @click.prevent="selectService(service)"
            :style="$getStyling(['border-bottom-color'], 'accent')"
            @mouseenter="getHover"
            @mouseleave="removeHover"
            >
              <img v-if="service.img" :src="service.img" :style="$getStyling(['border-color'], 'accent')" :title="service.title + ' | ' + service.description"/>
              <span>{{ $getServiceTime(service.time) }} <i v-if="service.price">| {{ service.price }} &euro;</i></span>

              {{ service.title }}

              <span v-if="service.description">{{ service.description }}</span>

            </a>
          </td>
        </tr>
      </tbody>
    </transition>

    </table>
  </div>

</template>

<script>

export default{

  props: {callback: Function},

  data(){

    var date = this.getDay();

    return{
      currDay: this.$getNullsBefore(date.getDate())+"."+this.$getNullsBefore(date.getMonth() + 1)+"."+date.getFullYear(),
      service: "",
    }

  },
  mounted () {
    this.$store.commit("setSettings", {approvedDates: false})
  },
  computed: {

    getServices(){


      var services = this.$store.getters.services;

      var service = this.getService();

      var r = [];
      var checkArray = [];
      var def = false

      service.forEach(function (check) {
        if (!check){
          def = true
          return true
        }
        services.forEach(function(val){
          if(val.short.indexOf(check) !== -1)
            if (checkArray.indexOf(val.ID) === -1) {
              r.push(val);
              checkArray.push(val.ID);
            }
        });
      })

      if (def) {
        r.push('default')
      }

      return r;


    }

  },

  methods: {
    openCalendar () {
      this.$store.commit("setSettings", {'viewBox': 'calendar', 'currentView': 2});
    },
    getHover (event) {
      var bg = this.$getStyling(['background-color'], 'accent')

      if (bg !== false && event.target.classList.contains('active')) {
        event.target.style['background-color'] = bg['background-color']
      }
    },
    removeHover (event) {
      var bg = this.$getStyling(['background-color'], 'accent')
      if (bg !== false && event.target.classList.contains('active')) {
        event.target.style['background-color'] = 'inherit'
      }
    },

    getService () {
      var atts = this.$store.getters.data

      var r = []

      Object.values(atts.include.weekdays).forEach(function (weekday) {
        if (typeof weekday[2] === "undefined") {
          return true
        }

        if (isNaN(parseInt(weekday[2]))) {
          if (r.indexOf(weekday[2]) === -1) {
            r.push(weekday[2])
          }
        } else {
          r.push(false)
        }
      });

      return r
    },
    getService2(){
      var atts = this.$store.getters.data;

      var weekday = Object.keys(atts.include.weekdays)[0];

      if(typeof atts.include.weekdays[weekday] != "undefined")
        return atts.include.weekdays[weekday][2];

      var r;
      for(var i = 0; i<(Object.keys(atts.include.weekdays).length); i++){

        weekday = Object.values(atts.include.weekdays)[i]

        if(typeof weekday === "object" && typeof weekday[2] !== "undefined"){
          r = weekday[2];
          break;

        }

      }
      return r;

    },
    selectService(service){

      this.callback(service);
    },

    getDay(){

      var date = this.$store.getters.date;
      date = date.split("-");
      date = new Date(date[0], date[1]-1, date[2]);
      return date;

    },
    getWeekday(){

      var weekday = this.getDay().getDay();

      if( weekday == 0 )
        weekday = 7;

      weekday--;

      return weekday;
    },


  }
}
</script>

  <style lang="sass" scoped>

    @import '@/style/variables.sass'
    @import '@/style/events.sass'
    @import '@/style/menu.sass'
    .app
      td > div, td > a.flix-active
        display: block
        padding-bottom: 20px
        margin-bottom: 20px
        > span
          font-size: 8pt
          display: block

        img
          border: 2px solid $active
          width: 16%
          margin-bottom: 10px
          &:hover
            border-color: $standard

        @media(max-width: 500px)
          img
            width: 100%

  </style>
