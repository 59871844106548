<template>

  <div :class="{flixFocus: focused}">


    <getLabel :data="data" :focus="focused" :key="focused" />
    <input spellcheck="false" v-model="input" :type="data.type" :placeholder="$store.getters.l.pleaseFillOut" :id="data.id" @focusin="focused = true" @focusout="focused = false" :class="{flixFocus: focused}">
    <div v-if="data.description" class="small" :class="{flixFocus: focused}">{{ data.description }}</div>


  </div>

</template>

<script>

import getLabel from '@/components/form/parts/label';

export default{

  props: {data: Object, callback: Function},
  components: {getLabel},

  watch:{

    input(val){

      if( this.data.subtype == "email"){

        this.input = val.toLowerCase();

        if(!this.checkEmail(val))
          val = false;

      }

      if( this.data.subtype == "tel"){

          this.input = val.replace(/[^0-9.]+/g, '');

      }

      this.callback(val)

    }
  },

  data(){

    return{
      focused: false,
      input: this.data.value
    }
  },

  methods:{

    checkTel(tel){

      if( typeof tel == "number" )
        return true;

      return false;

    },

    checkEmail(email){

      var re = /\S+@\S+\.\S+/
      return re.test(email)

    }
  }
}

</script>

<style lang="sass" scoped>
  @import '@/style/variables.sass'
  @import '@/style/form/label.sass'
  @import '@/style/form/text.sass'
</style>
