import Vue from 'vue';

var checkExcludeDates = function(atts){

  this.error = false;
  this.atts = atts;
  this.debug = {text: 'debugExcludeDates', status: "no", error: false}

  this.check = function(){


    if( typeof atts.exclude === "undefined" || atts.exclude.dates === null || atts.exclude.dates === "null" || typeof atts.exclude.dates !== "object" || Object.keys(atts.exclude.dates).length == 0 ){
      window.debugBookingCalendar.push(this.debug)
      return false;
    }

    this.fixedExcludedDates();

  }

  this.fixedExcludedDates = function(){


    JSON.parse(JSON.stringify(atts.exclude.dates)).forEach(function(slot){


      slot[0] = Vue.prototype.$generateDate(slot[0])
      slot[1] = Vue.prototype.$generateDate(slot[1])

      if (slot[0].getTime() != slot[1].getTime()) {
        slot[0].setMinutes(slot[0].getMinutes() + 1);
        slot[1].setMinutes(slot[1].getMinutes() * 1 - 1);
      }


      if( this.date.getTime() * 1 >= slot[0].getTime() * 1 && this.date.getTime() <= slot[1].getTime() ){
        this.debug.status = "yes"
        this.debug.error = true
        this.error = true;
        return false;
      }

    }.bind(this));

    window.debugBookingCalendar.push(this.debug)

  }

}

export default checkExcludeDates;
