var checkEnd = function(){

  this.error = true;
  this.debug = {text: 'debugEnd', status: "yes", error: true}

  this.check = function(){

    if( new Date().getTime() <= this.date.getTime() ){
      this.error = false;
      this.debug.status = 'no'
      this.debug.error = false
    }
    window.debugBookingCalendar.push(this.debug)
  }

}

export default checkEnd;
