<template>
  <div ref="flixScroll" class="app" :class="{'fullscreen': $store.state.fullscreen}" v-bind:key="JSON.stringify($store.getters.data)" >
    <div class="stage" :style="$getStyling(['border-color'], 'accent')">
      <live :getReload="function () { getReload() }" v-if="Object.keys($store.getters.data).length" />
      <div v-else class="loading">
        <loader/>
        {{ $store.getters.l.loading }}
      </div>
    </div>
  </div>

</template>

<script>
import Vue from 'vue'
import live from '@/components/live';
import loader from '@/components/loader';
import calculateView from '@/plugins/makeCalendar/calculateView'
import store from '@/store'
import plugin_main from '@/plugins/main.js'


Vue.use(plugin_main)

export default{
  store,
  components: { live, loader },
  props: { data: String, services: String, settings: String, callback: String },

  data(){

    return{
      productiveData: false
    }
  },

  mounted(){
    this.getScrollPosition()
    this.getLoad()
  },

  methods:{
    getReload () {
      this.$store.commit("setSettings", {'currentView': 0});
      if (this.$store.getters.viewBox === 'calendar') {
        this.$store.commit("setSettings", {'currentView': 2});
      }
      this.$store.commit('setSave', false)
      this.$store.commit("setData", {data: {}})
      this.getLoad()
    },
    getScrollPosition () {
      this.$store.commit('setScrollPosition', this.$refs.flixScroll)
    },
    getLoad () {
      this.loadQS();

      this.getData(function(data){
        if (typeof data.data.calendar === 'object'){
          this.$store.commit('setCalendars', data.data.calendar)
          data.data = Object.values(data.data.calendar)[0]
        }
        this.renderCalendar(data)

      }.bind(this));
    },
    getLoadTargetPage (data) {
      this.$store.getters.callback.getTargetPage({
        data: {
          user: data.user,
          ID: data.ID,
        },
        callback: function (ret) {
          this.$store.commit('setTargetPage', ret)
        }.bind(this)
      })
    },
    renderCalendar (data) {
      if (data.data.type === 'services') {
        data.data.type = 'datepicker'
      }
      this.$store.commit("setData", data);

      if(typeof data.data === 'object') {
        this.productiveData = data
      }
      this.getLoadTargetPage(data)
      this.calculateAlternativeListView();

      if(typeof data.services != "undefined")
        this.$store.commit("setServices", data.services);

      if(typeof this.productiveData.settings != "undefined")
        this.$store.commit("setSettings", this.productiveData.settings);
    },
    calculateAlternativeListView(){

      var cV = new calculateView(this.$store)
      cV.data = this.productiveData.data
      cV.run()
      cV.setView()


    },

    getData(callback){

      var raw = JSON.parse(atob(this.data));

      if( typeof raw.load != "undefined" ){

        this.$store.getters.callback.getCurlData(raw.load, function(data){ this.productiveData = Object.assign(raw, data); callback(data); }.bind(this));

        return false;
      }

      return callback(raw);

    },

    loadQS(){

      if(typeof this.callback == "undefined" || !this.callback)
        return false;

      var callback = "";
      eval( "callback = "+this.callback );

      callback = new callback();
      var qs = require('qs');
      callback.setQs(qs);

      callback = this.setCallbackUrl(callback);

      this.$store.commit("setCallback", callback);
    },

    setCallbackUrl(callback){

      var data = JSON.parse(atob(this.data));

      if( typeof data.load != "object" )
        return callback;

      /*
      if( typeof data.load.demo == "boolean" && data.load.demo == true )
        callback.setUrl("http://devapi.terminflix.de/de/");

      else
        callback.setUrl("https://api.terminflix.de/de/");
      */

      if( typeof data.load.lang == "string" ){
        callback.setUrl( callback.url.replace("/de/", "/"+data.load.lang+"/") );
        this.$store.commit("setLanguage", data.load.lang);
      }

      return callback;
    }

  },



}

</script>

<style lang="sass" scoped>
  @import '@/style/variables.sass'
  .app
    line-height: 1.5em
    &.fullscreen
      position: fixed
      top: 0
      left: 0
      width: 100%
      display: flex
      background-color: rgba(255,255,255,0.8)
      justify-content: center
      z-index: 1000005
      align-items: flex-start
      height: 100vh
      .stage
        flex: 1
        max-height: 100vh
        overflow-x: hidden
        overflow-y: auto
    .stage
      width: 100%
      max-width: 800px
      margin: 0 auto
      border: 1px solid $standard
      box-shadow: 0px 10px 10px -6px $standard
      padding: 5px
      box-sizing: border-box
      background-color: white

    .loading
      font-family: $font
      color: $active
      text-align: center
      font-size: 16pt
      font-weight: bold


</style>
