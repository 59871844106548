import Vue from 'vue';

var checkExcludeBookings = function(atts){

  this.error = false;
  this.atts = atts;
  this.debug = {text: 'debugExcludeBookings', status: "no", error: false}
  this.check = function(){

  var weekday = this.date.getDay();

  if(weekday == 0)
    weekday = 7;

  weekday = weekday -1;

  this.maxApplicants = [0, atts.max_applicants[weekday], 0]
  
  if( typeof atts.exclude === "undefined" || atts.exclude.bookings === null || atts.exclude.bookings === "null" || typeof atts.exclude.bookings !== "object" || Object.keys(atts.exclude.bookings).length == 0 ){
    window.debugBookingCalendar.push(this.debug)
    return false;
  }

  this.fixedExcludedDates(weekday);

  }

  this.fixedExcludedDates = function(weekday){


      JSON.parse(JSON.stringify(atts.exclude.bookings)).forEach(function(v){

        if(typeof v[0] == "undefined" || typeof v[4] == "undefined" )
          return true;

        var begin = Vue.prototype.$generateDate(v[0])
        var end = Vue.prototype.$generateDate(v[4])

        if (begin.getTime() != end.getTime()) {
          begin.setMinutes(begin.getMinutes() + 1);
          end.setMinutes(end.getMinutes() * 1 - 1);
        }

        if( (begin.getTime() * 1) <= (this.date.getTime() * 1) && (end.getTime() * 1) > (this.date.getTime() * 1) ){
          this.maxApplicants = [v[1], atts.max_applicants[weekday], v[3]]
          if(v[1] >= v[2]){
            this.error = true;
            this.debug.status = "yes";
            this.debug.error = true
            return false;
          }
        }


      }.bind(this));

      window.debugBookingCalendar.push(this.debug)


  }

}

export default checkExcludeBookings;
