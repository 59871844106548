<template>
  <a href="#" @click.prevent="callback()">
    <svg :class="{'previous': settings && settings.action === 'previous'}" version="1.1" width="30px" height="30px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 474.855 474.855" style="enable-background:new 0 0 474.855 474.855;" xml:space="preserve">
      <path d="M415.35,40.667V0h-17.103v40.667h-61.623V0h-17.119v40.667H250.73V0h-17.127v40.667h-71.41V0h-17.111v40.667H76.624V0H59.505v40.667H3.849v261.911h123.408l-69.678-69.703l21.638-21.655l106.647,106.647L79.201,424.539l-21.622-21.671l69.662-69.678H3.849v35.481v106.184h467.157V40.667H415.35z M455.481,137.259H19.383V56.185h40.123v36.554h17.119V56.185h68.459v36.554h17.103V56.185h71.41v36.554h17.119V56.185h68.776v36.554h17.119V56.185h61.623v36.554h17.103V56.185h40.139v81.074H455.481z"/>
    </svg>
    <span v-if="settings && settings.action === 'previous'">
      {{ $store.getters.l.previous }}
    </span>
    <span v-else>
      {{ $store.getters.l.next }}
    </span>
  </a>
</template>
<script>
export default {
  components: {},
  props: {
    settings: Object,
    callback: Function
  },
  mounted () {

  },
  computed: {

  },
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
  @import '@/style/variables.sass'
  a
    color: $standard
    text-decoration: none
    span
      color: $black
      font-size: 8pt
      display: block
  svg
    fill: $black
    &.previous
      transform: rotateY(180deg)
</style>
