<template>
  <div class="stars" v-if="$store.getters.data.survey.type === 'custom' && $store.getters.data.survey.show && amount">
    <stars size="50px" :star='star' />
    <br>
    <b>{{ star }} / 5</b>
    <small v-if="amount !== 1">( {{ $store.getters.l['ratesAmount'].split('{count}').join(amount).split('|')[1] }} )</small>
    <small v-else>( {{ $store.getters.l['ratesAmount'].split('{count}').join(amount).split('|')[0] }} )</small>
  </div>
</template>
<script>
export default {
  components: {
    stars () { return import('./calculate.vue') }
  },
  props: {},
  data () {
    return {
      star: this.$store.getters.data.hitklick.average * 1,
      amount: this.$store.getters.data.hitklick.count * 1
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
  .stars
    margin-bottom: 20px
    margin-top: 20px
    font-family: sans-serif
    b
      font-size: 16pt
      display: block
      margin-bottom: 0px
</style>
