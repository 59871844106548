<template>
  <span>
    <span v-if="!clickable">
      <span v-for="(s, index) in getStars" :key="index + 1" :title="index + 1">
        <star :style="'width:' + size + ''" :bg="s" />
      </span>
    </span>
    <span v-if="clickable">
      <a v-for="(s, index) in getStars" :key="index + 1" href="#" @click.prevent="setVote(index + 1)" :title="index + 1">
        <star :style="'width:' + size + ''" :bg="s" />
      </a>
    </span>
  </span>
</template>
<script>
export default {
  components: {
    star () { return import('./star') }
  },
  props: {
    star: {
      type: Number,
      default () { return 5 }
    },
    size: {
      type: String,
      default () { return '30px' }
    },
    clickable: {
      type: Boolean,
      default () { return false }
    },
    onClick: {
      type: Function,
      default () { return function () {} }
    }
  },
  data () {
    return {

    }
  },
  computed: {
    getStars () {
      const stars = []
      for (let i = 1; i <= 5; i++) {
        if (i <= this.star) {
          stars.push('full')
        } else if (i <= Math.ceil(this.star)) {
          const percentage = 1 - (Math.ceil(this.star) - this.star)
          if (percentage >= 0.75) {
            stars.push('threequarter')
          } else if (percentage >= 0.5) {
            stars.push('half')
          } else {
            stars.push('quarter')
          }
        } else {
          stars.push('none')
        }
      }
      return stars
    }
  },
  mounted () {

  },
  methods: {
    setVote (star) {
      this.onClick(star)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
