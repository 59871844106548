<script>

import form from '@/components/form/'

export default{
  extends: form,

  methods:{

    getCalendar(){

      this.$store.commit("setEvent", {});
      this.$store.commit('setView', -1)

    },

    getDateString(){

      var data = this.$store.getters.event;

      return data.time[0]+" "+data.time[1];

    },

  }
}

</script>
